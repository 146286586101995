import React, { useState } from 'react';
// import TextInput from '../../Shared/Core/TextInput';
import { ReactComponent as Step1Icon } from '../../../assets/svg/3steps-stepper-1-of-3.svg';
import { FormControlLabel, Input, Select, Switch } from '@mui/material';
import { ImagePicker, TextInput, TextAreaInput } from '@mola/client-components';
import { ReactComponent as ImageUploadSvg } from '../../../assets/svg/image-upload-svg.svg';
import { ReactComponent as ImagePickerIcon } from '../../../assets/svg/image-picker-icon.svg';

const Step1: React.FC<StepProps> = ({ goNext }) => {
  const [, setImage] = useState<File>();
  const [registered, setregistered] = useState(false);
  return (
    <div className="p-6">
      <h3 className="flex flex-row justify-between gap-20 text-primary text-xl pb-2">
        <span className="">Primary details</span> <Step1Icon className="mt-2 " />
      </h3>
      <div className="mt-6">
        <p className="font-bold text-dark-1 mb-2">Profile picture</p>
        <ImagePicker
          onImageUpload={(imageFile) => {
            setImage(imageFile);
          }}
          ImageUploadSvg={ImageUploadSvg}
          ImagePickerIcon={ImagePickerIcon}
        />
      </div>
      <TextInput
        labelKey="First Name"
        forText="firstName"
        name="firstName"
        isRequired
        classes={{}}
      />
      <TextInput labelKey="Last Name" forText="lastName" name="lastName" isRequired classes={{}} />
      <TextInput labelKey="Email" forText="email" name="email" isRequired classes={{}} />
      <TextInput
        labelKey="Phone Number"
        forText="phone"
        name="phone"
        isRequired={false}
        classes={{}}
      />

      <TextAreaInput
        labelKey="Adress (optional)"
        forText="adress"
        name="adress"
        isRequired={false}
        classes={{}}
      />
      <div className="mt-4 w-full">
        <label
          htmlFor="facility-category"
          className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
        >
          Highest level of education
        </label>
        <Select name="gender" id="user-gender" className="rounded-md h-10 mt-2 w-full px-1">
          <option value="certificate" key={0} className="hover:bg-grey-4 cursor-pointer">
            Certificate
          </option>
          <option value="diploma" key={0} className="hover:bg-grey-4 cursor-pointer">
            Diploma
          </option>
          <option value="bachelor" key={0} className="hover:bg-grey-4 cursor-pointer">
            Bachelor degree
          </option>
          <option value="masters" key={0} className="hover:bg-grey-4 cursor-pointer">
            Masters degree
          </option>
          <option value="phd" key={0} className="hover:bg-grey-4 cursor-pointer">
            PHD
          </option>
        </Select>
      </div>
      <div className="mt-4 w-full">
        <label
          htmlFor="facility-category"
          className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
        >
          Upload Certificate (Optional)
        </label>
        <Input type="file" className="w-full" placeholder="Upload Certificate (Optional)" />
      </div>
      <div className="mt-4 w-full">
        <FormControlLabel
          control={
            <Switch
              onChange={() => {
                setregistered(!registered);
              }}
              value={registered}
            />
          }
          label="Is He/ She a registered Practitioner? (Optional)"
          labelPlacement="start"
          className="text-dark-1 flex flex-row justify-between"
          sx={{ display: 'flex', marginLeft: 0 }}
        />
      </div>

      {registered && (
        <div className="mt-4 w-full">
          <label
            htmlFor="facility-category"
            className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
          >
            Highest level of education
          </label>
          <Select name="gender" id="user-gender" className="rounded-md h-10 mt-2 w-full px-1">
            <option value="certificate" key={0} className="hover:bg-grey-4 cursor-pointer">
              Board name 1
            </option>
            <option value="diploma" key={0} className="hover:bg-grey-4 cursor-pointer">
              Board name 2
            </option>
            <option value="bachelor" key={0} className="hover:bg-grey-4 cursor-pointer">
              Board name 3
            </option>
            <option value="masters" key={0} className="hover:bg-grey-4 cursor-pointer">
              Board name 4
            </option>
            <option value="phd" key={0} className="hover:bg-grey-4 cursor-pointer">
              Board name 5
            </option>
          </Select>
          <TextInput
            labelKey="Board number"
            forText="phone"
            name="phone"
            isRequired={false}
            classes={{}}
          />
        </div>
      )}
      <div className="flex flex-row justify-between">
        <span>&nbsp;</span>
        <button
          type="button"
          className="button flex gap-2 mt-10"
          onClick={() => {
            goNext();
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

interface StepProps {
  goNext: () => void;
}

export default Step1;
