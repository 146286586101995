import React, { useEffect, useState } from 'react';
import { Select } from '@mui/material';
import { connect } from 'react-redux';
import {
  Button,
  FacilityOfferedAtOptions,
  FacilityServiceType,
  TextAreaInput,
  TextInput,
} from '@mola/client-components';
import { CreateFacilityServiceDTO } from '../../../@types/redux/appRessourceStates/services';
import { ModalsState } from '../../../@types/redux/appRessourceStates/modals';
import { createService } from '../../../store/actions/creators/services';
import { setCurrentModalType } from '../../../store/actions/creators/modals';
import FeesEditor from '../../Shared/FeesEditor';

const Consultation: React.FC<ConsultationProps> = ({
  currentFacility,
  _createService,
  isLoadingCurrentFacility,
  _setCurrentModalType,
  serviceCategory,
  selectedService,
}) => {
  const [fees, setFees] = useState<ServiceRequestFee[]>([]);
  const [fields, setFields] = useState({
    type: serviceCategory,
    offeredAt: FacilityOfferedAtOptions.FACILITY,
    description: '',
    totalPrice: 0,
    priceDescription: '',
    name: '',
    instructions: '',
    isTravelRequired: false,
    emergencyPhoneNumber: currentFacility?.phoneNumber as string,
    emergencyEmail: currentFacility?.email as string,
    collectionAtPatientResidence: false,
    requiredAdditionalInfo: 'N/A',
  });

  useEffect(() => {
    if (selectedService) {
      setFields((stateFields) => ({
        ...stateFields,
        ...selectedService,
        type: selectedService.type,
        name: selectedService.name,
        description: selectedService.description,
      }));
    }
  }, [selectedService]);

  return (
    <div>
      <TextInput
        labelKey="Package Name "
        forText="packageName"
        name="name"
        isRequired
        classes={{}}
        onChange={(value) => {
          setFields({ ...fields, name: value });
        }}
        value={fields.name}
        disabled={isLoadingCurrentFacility}
      />

      <TextAreaInput
        labelKey="Description "
        forText="description"
        name="description"
        isRequired
        classes={{}}
        onChange={(value) => {
          setFields({ ...fields, description: value });
        }}
        value={fields.description}
        disabled={isLoadingCurrentFacility}
      />

      <TextAreaInput
        labelKey="Service Instructions  "
        forText="instructions"
        name="instructions"
        isRequired
        classes={{}}
        onChange={(value) => {
          setFields({ ...fields, instructions: value });
        }}
        value={fields.instructions}
        disabled={isLoadingCurrentFacility}
      />

      <FeesEditor
        emitFees={(nextFees) => {
          setFees(nextFees);
        }}
        initialFees={fees}
      />

      <div className="mt-4 w-full">
        <label
          htmlFor="facility-category"
          className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
        >
          Offered at <span className="text-gray-400">*</span>
        </label>
        <Select
          name="offeredAt"
          id="offeredAt"
          className="rounded-md h-10 mt-2 w-full px-1"
          onChange={({ target: { value } }) => {
            setFields({ ...fields, offeredAt: value as FacilityOfferedAtOptions });
          }}
          value={fields.offeredAt}
          disabled={isLoadingCurrentFacility}
          native
        >
          {Object.values(FacilityOfferedAtOptions).map((el) => (
            <option value={el} key={Math.random()} className="hover:bg-grey-4 cursor-pointer">
              {el}
            </option>
          ))}
        </Select>
      </div>

      <div className="flex justify-between mt-4">
        <p>Total Price (Ksh) </p>{' '}
        <p>
          {new Intl.NumberFormat().format(fees.map((el) => el.amount).reduce((a, b) => a + b, 0))}
        </p>
      </div>

      <div className="flex flex-row justify-between">
        <span>&nbsp;</span>
        <Button
          disabled={isLoadingCurrentFacility}
          type="button"
          className="button flex gap-2 mt-10"
          onClick={() => {
            _createService(
              {
                ...fields,
                priceDescription: JSON.stringify(fees),
                totalPrice: fees.map((el) => el.amount).reduce((a, b) => a + b, 0),
              },
              currentFacility?.id as string,
              selectedService?.id as string,
            ).then(({ payload }) => {
              if (typeof payload !== 'string') {
                _setCurrentModalType({ type: null, minWidth: 'sm' });
              }
            });
          }}
          isLoading={isLoadingCurrentFacility}
        >
          {!selectedService ? 'Add Service' : 'Update Service'}
        </Button>
      </div>
    </div>
  );
};

interface ConsultationProps {
  currentFacility: FacilityI | null;
  _createService: (
    formData: CreateFacilityServiceDTO,
    facilityId: string,
    serviceId?: string,
  ) => Promise<{
    type: string;
    payload: any;
  }>;
  isLoadingCurrentFacility: boolean;
  _setCurrentModalType: (options: ModalsState['currentModal']) => {
    type: string;
    payload: ModalsState['currentModal'];
  };
  serviceCategory: FacilityServiceType;
  selectedService?: FacilityServiceI | null;
}

const mapStateToProps = ({
  registrationState: { currentFacility, isLoadingCurrentFacility },
}: StoreI) => ({
  currentFacility,
  isLoadingCurrentFacility,
});

export default connect(mapStateToProps, {
  _createService: createService,
  _setCurrentModalType: setCurrentModalType,
})(Consultation);
