import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import Routes from './routes';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import store from './store';
import { ToastContainer } from 'react-toastify';

const outerTheme = createTheme({
  palette: {
    primary: {
      main: '#0AB2B2',
    },
    secondary: {
      main: '#3F8CFF',
    },
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={outerTheme}>
        <Provider store={store}>
          <Routes />
        </Provider>
      </ThemeProvider>
      <ToastContainer
        hideProgressBar
        closeButton={false}
        position="bottom-left"
        className="rounded"
        autoClose={3000}
      />
    </>
  );
}

export default App;
