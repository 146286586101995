import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { SetCurrentModalActionI } from '../../../@types/redux/actionCreators/modals';
import { ModalsActionTypes } from '../types/modals';
import { ModalsOptions } from '../../../@types/redux/appRessourceStates/modals';

export const setCurrentModalType: ActionCreator<
  ThunkAction<
    {
      type: string;
      payload: { type: ModalsOptions | null; minWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' };
    },
    {
      type: string;
      payload: { type: ModalsOptions | null; minWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' };
    },
    string | null,
    SetCurrentModalActionI
  >
> =
  (options: { type: ModalsOptions | null; minWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' }) =>
  (dispatch: Dispatch) => {
    return dispatch({
      type: ModalsActionTypes.SET_CURRENT_MODAL,
      payload: options,
    });
  };
