import React from 'react';
import {
  Button,
  FacilityServiceType,
  SearchInput,
  TextAreaInput,
  TextInput,
} from '@mola/client-components';
import { ReactComponent as CheckIcon } from '../../../assets/svg/check-icon.svg';
import { ReactComponent as SearchIcon } from '../../../assets/svg/search-icon.svg';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { completeRequestOrder } from '../../../store/actions/creators/requests';
import { setCurrentModalType } from '../../../store/actions/creators/modals';
import { ModalsState } from '../../../@types/redux/appRessourceStates/modals';
import { FileInput } from './CompleteRequestPopover/FileInput';
import { saveImage } from '../../../shared/helpers/saveImage';

const initialState = {
  TTNumber: '',
  donatedBloodQuantity: '',
  howToUse: '',
};

const CompleteOrderPopOver: React.FC<CompleteOrderPopOverProps> = ({
  _completeRequestOrder,
  request,
  _setCurrentModalType,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [file, setFile] = React.useState<File | null>(null);
  const [fileUrl, setFileUrl] = React.useState<string>('');

  React.useEffect(() => {
    if (file && !isUploading) {
      setIsUploading(true);
      saveImage(file)
        .then((url) => {
          setFileUrl(url);
        })
        .catch((error) => {
          toast.error(error.message || 'Something went wrong while uploading the file, try again!');
          setFileUrl('');
          setFile(null);
        })
        .finally(() => {
          setIsUploading(false);
        });
    }
    // eslint-disable-next-line
  }, [file]);

  const requestType: FacilityServiceType = request.requestedService.type;

  return (
    <Formik
      onSubmit={() => {
        // _login(values);
      }}
      validateOnChange
      initialValues={initialState}
    >
      {(formikProps) => {
        const { handleChange, values } = formikProps;

        return (
          <div className="p-6">
            <h3>Complete Order</h3>

            {(requestType === FacilityServiceType.LAB ||
              requestType === FacilityServiceType.VACCINATION) && (
              <FileInput
                containerClassName="mt-10"
                onChange={(value: File | null) => setFile(value)}
                value={file}
                placeholder={
                  requestType === FacilityServiceType.VACCINATION
                    ? 'Upload vaccine certificate (optional)'
                    : 'Upload test results (optional)'
                }
                loading={isUploading}
              />
            )}

            {(requestType === FacilityServiceType.LAB ||
              requestType === FacilityServiceType.VACCINATION) && (
              <TextInput
                forText="TT Number"
                labelKey="TT Number (Optional)"
                placeHolder="TT Number"
                name="TTNumber"
                isRequired={false}
                classes={{}}
                onChange={(value, event) => {
                  handleChange(event);
                }}
                value={values.TTNumber}
              />
            )}

            {requestType === FacilityServiceType.BLOOD_REQUEST && (
              <TextInput
                forText="Quantity"
                labelKey="Donated quantity in ml (Optional)"
                placeHolder="Quantity"
                name="donatedBloodQuantity"
                type="number"
                isRequired={false}
                classes={{}}
                onChange={(value, event) => {
                  handleChange(event);
                }}
                value={values.donatedBloodQuantity}
              />
            )}

            {requestType === FacilityServiceType.PHARMACY && (
              <TextAreaInput
                forText="How to use"
                labelKey="How to use (Optional)"
                name="howToUse"
                isRequired={false}
                classes={{}}
                value={values.howToUse}
                onChange={(value, event) => {
                  console.log('value', value, values.howToUse);
                  handleChange(event);
                }}
              />
            )}

            <SearchInput
              labelKey="Add followup program (optional)"
              forText="search"
              name="lastName"
              isRequired={false}
              classes={{}}
              placeHolder="Search follow up program"
              onSearchClicked={() => {}}
              style={undefined}
              getSearchIcon={() => <SearchIcon />}
            />

            <Button
              type="button"
              className="button flex gap-2 mt-10"
              isLoading={isLoading}
              onClick={() => {
                setIsLoading(true);
                _completeRequestOrder(request, {
                  TTNumber: values.TTNumber,
                  testResultsReportLink:
                    requestType === FacilityServiceType.LAB ? fileUrl : undefined,
                  vaccinationCertificateLink:
                    requestType === FacilityServiceType.VACCINATION ? fileUrl : undefined,
                  donatedBloodQuantity: values.donatedBloodQuantity,
                }).then(({ payload }) => {
                  setIsLoading(false);
                  if (typeof payload !== 'string') {
                    _setCurrentModalType({ type: null, minWidth: 'sm' });
                  }
                });
              }}
            >
              <CheckIcon className="mt-1" />
              Complete Order
            </Button>
          </div>
        );
      }}
    </Formik>
  );
};

interface CompleteOrderPopOverProps {
  _completeRequestOrder: (
    request: RequestI,
    additionalData: {
      TTNumber?: string;
      testResultsReportLink?: string;
      vaccinationCertificateLink?: string;
      donatedBloodQuantity?: string;
    },
  ) => Promise<{
    type: string;
    payload: RequestI | string;
  }>;
  request: RequestI;
  _setCurrentModalType: (options: ModalsState['currentModal']) => {
    type: string;
    payload: ModalsState['currentModal'];
  };
}

export default connect(null, {
  _completeRequestOrder: completeRequestOrder,
  _setCurrentModalType: setCurrentModalType,
})(CompleteOrderPopOver);
