import React from 'react';
import { FormControlLabel, Select, Switch } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import AccordionDetails from '@mui/material/AccordionDetails';
import { SearchInput, TextInput } from '@mola/client-components';
import { ReactComponent as SearchIcon } from '../../../assets/svg/search-icon.svg';
import VaccineDisplay from './VaccineDisplay';
import { FacilityServiceType } from '@mola/client-components';

const initialState = {
  serviceType: FacilityServiceType.CONSULTATION,
  isVaccinationOut: false,
};

const VaccinationDetails: React.FC<VaccinationDetailsProps> = ({
  practitioners,
  fields,
  setFields,
}) => {
  return (
    <>
      <FormControlLabel
        control={
          <Switch
            onChange={() => {
              setFields({
                ...fields,
                isVaccinationOut: !fields.isVaccinationOut,
              });
            }}
            value={fields.isVaccinationOut}
          />
        }
        label="is the vaccination taking place out fo the facility ?"
        labelPlacement="start"
        className="text-dark-3 flex flex-row justify-between"
        sx={{ display: 'flex', marginLeft: '0' }}
      />
      {fields.isVaccinationOut && (
        <>
          <div className="mt-4 w-full">
            <label
              htmlFor="facility-category"
              className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
            >
              Practitioner <span className="text-gray-400">*</span>
            </label>
            <Select
              name="gender"
              id="user-gender"
              className="rounded-md h-10 mt-2 w-full px-1"
              native
            >
              <option key={Math.random()} className="hover:bg-grey-4 cursor-pointer px-2 py-1">
                &nbsp;
              </option>
              {practitioners.map((value) => (
                <option
                  value={value}
                  key={Math.random()}
                  className="hover:bg-grey-4 cursor-pointer px-2 py-1"
                >
                  {value}
                </option>
              ))}
            </Select>
          </div>
          <TextInput
            labelKey="Location Address"
            forText="locationAddress"
            isRequired={false}
            classes={{}}
          />
          <TextInput labelKey="Fees (Ksh)" forText="fees" isRequired={false} classes={{}} />
          <br />
          <br />
          <Accordion
            sx={{
              boxShadow: 'unset',
            }}
          >
            <AccordionSummary
              expandIcon={<FontAwesomeIcon icon={faChevronDown} className="text-dark" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h3 className="text-dark font-bold">Vaccination Details</h3>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <SearchInput
                  labelKey=""
                  forText="searchVaccine"
                  isRequired={false}
                  classes={{}}
                  placeHolder="Search vaccine"
                  onSearchClicked={() => {}}
                  getSearchIcon={() => <SearchIcon />}
                />
              </div>
              <VaccineDisplay
                title="Covid 19 Vaccine"
                description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi dignissimos
                dolorem dolores eaque ex facere hic iusto, labore officia porro recusandae, rem
                repellendus veritatis? Aliquid assumenda fuga id magni vel?"
                price={250}
                feesDetails={{
                  'Consultation fees': 300,
                  'Material fees': 200,
                  'Mola fees': 100,
                }}
              />
              <VaccineDisplay
                title="Hepatitis B Vaccine"
                description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi dignissimos
                dolorem dolores eaque ex facere hic iusto, labore officia porro recusandae, rem
                repellendus veritatis? Aliquid assumenda fuga id magni vel?"
                price={250}
                feesDetails={{
                  'Consultation fees': 300,
                  'Material fees': 200,
                  'Mola fees': 100,
                }}
              />
              <VaccineDisplay
                title="Hepatitis C vaccine"
                description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi dignissimos
                dolorem dolores eaque ex facere hic iusto, labore officia porro recusandae, rem
                repellendus veritatis? Aliquid assumenda fuga id magni vel?"
                price={250}
                feesDetails={{
                  'Consultation fees': 300,
                  'Material fees': 200,
                  'Mola fees': 100,
                }}
              />
              <VaccineDisplay
                title="Yellow fever vaccine"
                description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi dignissimos
                dolorem dolores eaque ex facere hic iusto, labore officia porro recusandae, rem
                repellendus veritatis? Aliquid assumenda fuga id magni vel?"
                price={250}
                feesDetails={{
                  'Consultation fees': 300,
                  'Material fees': 200,
                  'Mola fees': 100,
                }}
              />
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </>
  );
};

interface VaccinationDetailsProps {
  practitioners: string[];
  fields: typeof initialState;
  setFields: (value: typeof initialState) => void;
}

export default VaccinationDetails;
