export enum RequestsActionTypes {
  SET_ALL_REQUESTS = 'Requests/SET_ALL_REQUESTS',
  SET_CURRENTLY_DISPLAYED_REQUEST = 'Requests/SET_CURRENTLY_DISPLAYED_REQUEST',
  SET_IS_LOADING_ALL_REQUESTS = 'Requests/SET_IS_LOADING_ALL_REQUESTS',
  CONFIRM_CASH_PAYMENT = 'Requests/CONFIRM_CASH_PAYMENT',
  REJECT_REQUEST = 'Requests/REJECT_REQUEST',
  QUEUE_REQUEST = 'Requests/QUEUE_REQUEST',
  COMPLETE_REQUEST_ORDER = 'Requests/COMPLETE_REQUEST_ORDER',
  SET_IS_LOADING_ASSIGN_PRACTITIONER = 'Requests/SET_IS_LOADING_ASSIGN_PRACTITIONER',
  ASSIGN_PRACTITIONER = 'Requests/ASSIGN_PRACTITIONER',
  SET_ERROR_ASSIGN_PRACTITIONER = 'Services/SET_ERROR_ASSIGN_PRACTITIONER',
}
