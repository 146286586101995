import React from 'react';
import { connect } from 'react-redux';
import { Avatar, Popover } from '@mui/material';
import QRCode from 'react-qr-code';
import { ReactComponent as UserIcon } from '../../../assets/svg/user-kamana-icon.svg';
import ServiceTypeDisplay from '../../Shared/ServiceTypeDisplay';
import UserDefaultAvatar from '../../../assets/img/avatar.png';
import FacilityLocation from '../../../assets/svg/facility-location.svg';
import RequestDetailsModalActionSelector from './RequestDetailsModalActionSelector';
import { TextAreaInput } from '@mola/client-components';

const RequestDetailsModal: React.FC<RequestViewModalProps> = ({ request }) => {
  const [showFacilityAnchorEl, setShowFacilityAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const showFacilityLocation = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowFacilityAnchorEl(event.currentTarget);
  };
  const hideFacilityLocation = () => {
    setShowFacilityAnchorEl(null);
  };

  const isFacilityLocationShown = Boolean(showFacilityAnchorEl);
  const facilityLocationId = isFacilityLocationShown
    ? 'simple-popover-facilityLocationId'
    : undefined;

  const fees = request?.requestedService.priceDescription;
  let priceDescription: ServiceRequestFee[] = [];

  if (fees) {
    priceDescription = JSON.parse(fees);
  }

  return (
    <>
      {request && (
        <div className="flex gap-x-5 py-4 px-4">
          <div className="p-4 w-ratio-60">
            <p className="text-grey rounded border border-double py-1 border-dark-4 px-4">
              Request # {request.molaId}
            </p>

            <div className="flex justify-between mt-10">
              <p className=" text-3xl text-black">{request.requestedService.name} </p>
              <p className="text-3xl text-black">{request.requestedService.totalPrice}</p>
            </div>

            <div className="text-2xl mt-8">Description</div>

            <div
              className="mt-6"
              style={{
                minWidth: 500,
              }}
            >
              {request.requestedService.description}
            </div>

            <div className="mt-10">
              {priceDescription.map((fee) => (
                <div className="flex justify-between pt-2" key={Math.random()}>
                  <p className="">{fee.name}</p>
                  <p className="text-grey-6">Ksh {fee.amount}</p>
                </div>
              ))}
            </div>
            <div className="mt-10 flex gap-5">
              <Avatar alt="Trevor Henderson" src={UserDefaultAvatar} />
              <TextAreaInput
                forText="user-comment"
                labelKey=""
                placeHolder="Add comment"
                isRequired={false}
                classes={{
                  container: 'w-full sm:p-0 sm:m-0',
                  input: 'h-32',
                }}
              />
            </div>
          </div>
          <div className="p-4">
            <RequestDetailsModalActionSelector request={request} />
            <div className="flex justify-between items-center mt-6">
              <p className="text-sm font-bold">Service</p>
              {request.requestedService && (
                <ServiceTypeDisplay requestedServiceType={request.requestedService.type} />
              )}
            </div>

            <div className="flex justify-between items-center my-4">
              <p className="text-sm font-bold">Patient</p>
              <div className="flex gap-x-1 items-center">
                <UserIcon />
                <p className="text-sm text-grey-6">{request.author.firstName}</p>
              </div>
            </div>
            <div className="flex justify-between  items-center my-4">
              <p className="text-sm font-bold">Mola ID</p>
              <p className="text-xs text-grey-6">{request.author.molaID}</p>
            </div>
            <div className="flex justify-between items-center my-4">
              <p className="text-sm font-bold">Assignee</p>
              <p className="text-sm text-grey-6">
                {request.practitioner
                  ? `${request.practitioner.user.firstName} ${request.practitioner.user.lastName}`
                  : 'Not assigned'}
              </p>
            </div>
            <div className="flex justify-between items-center my-4">
              <p className="text-sm font-bold">Start date & time</p>
              <p className="text-sm text-grey-6">
                {new Date(request.createdAt).toLocaleDateString()} |{' '}
                {new Date(request.createdAt).toLocaleTimeString()}
              </p>
            </div>
            <div className="flex justify-between items-center my-4">
              <p className="text-sm font-bold">End date & time</p>
              <p className="text-sm text-grey-6">
                {new Date(request.dueTime).toLocaleDateString()} |{' '}
                {new Date(request.dueTime).toLocaleTimeString()}
              </p>
            </div>
            <div className="flex justify-between items-center my-4">
              <p className="text-sm font-bold">Venue</p>
              <button
                className="text-sm text-grey-6 underline text-primary"
                onMouseOver={showFacilityLocation}
                onFocus={() => {}}
              >
                Facility
              </button>
            </div>
            <div className="flex justify-between items-center my-4">
              <p className="text-sm font-bold">Payment Method</p>
              <p className="text-sm text-grey-6">{request.payment?.channel}</p>
            </div>

            <div className="mt-8">
              <p className="text-grey-5 text-lg">Order QR Code</p>
              <div className="mt-2 w-40">
                <QRCode value={request.molaId} size={150} />
              </div>
            </div>
          </div>

          <Popover
            id={facilityLocationId}
            open={isFacilityLocationShown}
            anchorEl={showFacilityAnchorEl}
            onClose={hideFacilityLocation}
            anchorOrigin={{
              vertical: 40,
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <div className="px-4 py-6">
              <img alt="logo" src={FacilityLocation} width={100} />
            </div>
          </Popover>
        </div>
      )}
    </>
  );
};

interface RequestViewModalProps {
  request: Merge<RequestI, { payment?: PaymentI }> | null;
}

const mapStateToProps = ({ requestsState: { request } }: StoreI) => ({
  request,
});

export default connect(mapStateToProps)(RequestDetailsModal);
