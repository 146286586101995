import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  EditFacilityActionI,
  RegistrationActions,
  RegistrationErrorActionI,
  SetCurrentFacilityActionI,
  SetCurrentUserActionI,
  SetIsEditingFacilityActionI,
  SetIsLoadingActionI,
  SetIsLoadingCurrentFacilityActionI,
  SetTokensActionI,
  SetTokensStorageActionI,
} from '../../@types/redux/actionCreators/registration';
import { RegistrationActionTypes } from '../actions/types/registration';
import { toast } from 'react-toastify';
import { RegistrationState } from '../../@types/redux/appRessourceStates/registration';

export const registrationReducer: Reducer<RegistrationState, RegistrationActions> = (
  state = initialState.registrationState,
  action,
) => {
  switch (action.type) {
    case RegistrationActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: (action as SetCurrentUserActionI).payload.user,
        isAuth: (action as SetCurrentUserActionI).payload.isAuth,
        tokensStorage: (action as SetCurrentUserActionI).payload.storage,
        errorMessage: '',
      };

    case RegistrationActionTypes.SET_TOKENS_STORAGE:
      return {
        ...state,
        tokensStorage: (action as SetTokensStorageActionI).payload,
      };

    case RegistrationActionTypes.REGISTRATION_ERROR:
      if (
        (action as RegistrationErrorActionI).payload &&
        (action as RegistrationErrorActionI).payload !== '' &&
        (action as RegistrationErrorActionI).payload !== state.message
      ) {
        toast.error((action as RegistrationErrorActionI).payload);
      }

      return {
        ...state,
        errorMessage: (action as RegistrationErrorActionI).payload,
        isLoading: false,
      };

    case RegistrationActionTypes.SET_TOKENS_VALUE:
      return {
        ...state,
        tokens: (action as SetTokensActionI).payload,
      };

    case RegistrationActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: (action as SetIsLoadingActionI).payload,
      };

    case RegistrationActionTypes.SET_CURRENT_FACILITY:
      return {
        ...state,
        currentFacility: (action as SetCurrentFacilityActionI).payload,
        isLoadingCurrentFacility: false,
      };

    case RegistrationActionTypes.SET_IS_LOADING_CURRENT_FACILITY_DATA:
      return {
        ...state,
        isLoadingCurrentFacility: (action as SetIsLoadingCurrentFacilityActionI).payload,
      };

    case RegistrationActionTypes.EDIT_FACILITY:
      toast.success('Updated Successfully', { autoClose: 3000 });
      return {
        ...state,
        currentFacility: (action as EditFacilityActionI).payload,
      };

    case RegistrationActionTypes.SET_IS_EDITING_FACILITY:
      return {
        ...state,
        isEditingFacility: (action as SetIsEditingFacilityActionI).payload,
      };

    default:
      return state;
  }
};
