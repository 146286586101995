import { Reducer } from 'redux';
import initialState from '../initialState';
import { ServicesState } from '../../@types/redux/appRessourceStates/services';
import {
  CreateServiceActionI,
  ServicesActions,
  SetCurrentErrorActionI,
  SetCurrentServicesActionI,
  SetIsLoadingCurrentServicesActionI,
  SetSelectedServiceActionI,
} from '../../@types/redux/actionCreators/services';
import { ServicesActionTypes } from '../actions/types/services';
import { toast } from 'react-toastify';

export const servicesReducer: Reducer<ServicesState, ServicesActions> = (
  state = initialState.servicesState,
  action,
): ServicesState => {
  switch (action.type) {
    case ServicesActionTypes.SET_ERROR_MESSAGE:
      toast.error((action as SetCurrentErrorActionI).payload);

      return {
        ...state,
        isLoadingCurrentServices: false,
        currentError: (action as SetCurrentErrorActionI).payload,
      };

    case ServicesActionTypes.SET_IS_LOADING_CURRENT_SERVICES:
      return {
        ...state,
        isLoadingCurrentServices: (action as SetIsLoadingCurrentServicesActionI).payload,
      };

    case ServicesActionTypes.SET_CURRENT_SERVICES:
      return {
        ...state,
        isLoadingCurrentServices: false,
        currentServices: (action as SetCurrentServicesActionI).payload,
      };

    case ServicesActionTypes.CREATE_SERVICE:
      toast.success('Service created successfully');

      return {
        ...state,
        isLoadingCurrentServices: false,
        currentServices: {
          ...state.currentServices,
          rows: [(action as CreateServiceActionI).payload, ...state.currentServices.rows],
        },
      };

    case ServicesActionTypes.SET_IS_LOADING_DELETE_SERVICE:
      return {
        ...state,
        deleteService: {
          ...state.deleteService,
          loading: true,
        },
      };

    case ServicesActionTypes.DELETE_SERVICE:
      toast.success('Service deleted successfully');

      return {
        ...state,
        currentServices: {
          ...state.currentServices,
          rows: [
            ...state.currentServices.rows.filter(
              (service: FacilityServiceI) => service.id !== action.payload,
            ),
          ],
        },
        deleteService: {
          ...state.deleteService,
          loading: false,
          data: 'Service deleted successfully',
        },
      };

    case ServicesActionTypes.SET_ERROR_DELETE_SERVICE:
      return {
        ...state,
        deleteService: {
          ...state.deleteService,
          loading: false,
          data: null,
        },
      };

    case ServicesActionTypes.SET_SELECTED_SERVICE:
      return {
        ...state,
        selectedService: (action as SetSelectedServiceActionI).payload,
      };

    case ServicesActionTypes.UPDATE_SERVICE:
      toast.success('Service updated successfully');
      return {
        ...state,
        selectedService: { ...state.selectedService, ...action.payload } as FacilityServiceI,
        isLoadingCurrentServices: false,
        currentServices: {
          ...state.currentServices,
          rows: [
            ...state.currentServices.rows.map((service: FacilityServiceI) => {
              if (service.id === action.payload.id) {
                return { ...service, ...action.payload };
              }
              return service;
            }),
          ],
        },
      };

    default:
      return state;
  }
};
