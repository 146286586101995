import { Button } from '@mola/client-components';
import { ReactComponent as UploadIcon } from '../../../../assets/svg/upload-icon.svg';
import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';

interface FileInputProps {
  containerClassName?: string;
  inputClassName?: string;
  onChange?: (value: File | null) => void;
  value?: File | null;
  loading?: boolean;
  placeholder?: string;
}

export const FileInput = ({
  containerClassName,
  inputClassName,
  onChange,
  value,
  loading,
  placeholder,
}: FileInputProps) => {
  const [internalValue, setIntervalValue] = useState(value);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  return (
    <div className={`overflow-x-hidden ${containerClassName}`}>
      {!loading && <p className="max-w-sm text-xs m-0 truncate">{internalValue?.name}</p>}
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="raised-button-file"
        ref={fileInputRef}
        type="file"
        onChange={(event) => {
          setIntervalValue(event.target.files ? event.target.files[0] : null);
          if (onChange) {
            onChange(event.target.files ? event.target.files[0] : null);
          }
        }}
      />
      <Button
        className={`flex justify-center items-center rounded-md w-full h-10 mt-2 px-2 border border-dark-4 text-gray-400 ${inputClassName} `}
        onClick={() => fileInputRef.current?.click()}
      >
        {loading ? (
          <CircularProgress size={18} className="mr-3" />
        ) : (
          <UploadIcon className="mr-3" />
        )}{' '}
        <span>{placeholder}</span>
      </Button>
    </div>
  );
};

export default FileInput;
