import React, { useState } from 'react';
import { NumberInput, TextInput, TextInputType } from '@mola/client-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@mui/material';

const FeesEditor: React.FC<FeesEditorProps> = ({ emitFees, initialFees = [] }) => {
  const [fees, setFees] = useState<ServiceRequestFee[]>(initialFees);

  const [isFeesEditorShown, setIsFeesEditorShown] = useState(false);
  const [feeInEdit, setFeeInEdit] = useState<ServiceRequestFee>({
    amount: 0,
    name: '',
  });

  return (
    <>
      <div className="">
        {fees.length !== 0 && (
          <>
            <h3 className="text-dark font-bold mt-4">Fees</h3>
            <hr />
          </>
        )}

        <ul className="px-2 rounded py-2 list-disc mt-2">
          {fees.map((fee) => (
            <li className="ml-6 mb-1" key={Math.random()}>
              <span className="mr-6">{fee.name}:</span>
              <span>Kes {fee.amount}</span>
            </li>
          ))}
        </ul>
      </div>

      <button
        className="text-secondary mt-3"
        onClick={() => {
          setIsFeesEditorShown(!isFeesEditorShown);
        }}
      >
        {isFeesEditorShown ? 'Complete adding' : '+ Add'} Fees
      </button>

      {isFeesEditorShown && (
        <div className="flex gap-5">
          <TextInput
            labelKey="Fee Name "
            forText="packageName"
            name="packageName"
            isRequired
            classes={{
              container: 'w-3/4',
            }}
            value={feeInEdit.name}
            onChange={(value) => {
              setFeeInEdit({
                ...feeInEdit,
                name: value,
              });
            }}
          />
          <NumberInput
            labelKey="Amount (Ksh) "
            forText="amount"
            name="amount"
            isRequired
            classes={{
              input: 'outline-none border border-dark-4 rounded-lg h-10',
            }}
            value={String(feeInEdit.amount)}
            onChange={(value) => {
              setFeeInEdit({
                ...feeInEdit,
                amount: Number(value),
              });
            }}
            allowNegative={false}
            decimalScale={0}
            type={TextInputType.NUMBER}
          />
          <Tooltip title="Add fee" placement="top">
            <button
              type="button"
              className="button px-2 sm:py-0 h-10 flex gap-2 mt-12 mb-0"
              onClick={() => {
                setFees([...fees, feeInEdit]);
                setFeeInEdit({ amount: 0, name: '' });
                if (emitFees) {
                  emitFees([...fees, feeInEdit]);
                }
              }}
            >
              <FontAwesomeIcon icon={faPlus} className="text-white mt-3" />
            </button>
          </Tooltip>
        </div>
      )}
    </>
  );
};

interface FeesEditorProps {
  initialFees?: ServiceRequestFee[];
  emitFees?: (fees: ServiceRequestFee[]) => void;
}

export default FeesEditor;
