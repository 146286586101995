import React from 'react';
import { SearchInput } from '@mola/client-components';
import { ReactComponent as Step1Icon } from '../../../assets/svg/4StepsSterpper-step3.svg';
import { ReactComponent as SearchIcon } from '../../../assets/svg/search-icon.svg';
import VaccineDisplay from './VaccineDisplay';

const Step3: React.FC<StepProps> = ({ goBack, goNext }) => {
  return (
    <div className="p-6">
      <h3 className="flex flex-row justify-between gap-20 text-primary text-xl pb-2">
        <span className="">Package details</span> <Step1Icon className="mt-2 " />
      </h3>

      <SearchInput
        labelKey="Search package (optional)"
        forText="search"
        name="searchPackage"
        isRequired={false}
        classes={{}}
        placeHolder="Search package"
        onSearchClicked={() => {}}
        getSearchIcon={() => <SearchIcon />}
      />
      <VaccineDisplay
        title="General Consultation"
        description="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis "
        price={250}
        feesDetails={{ ['Consultation fees']: 100 }}
      />

      <VaccineDisplay
        title="Dental Consultation"
        description="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis "
        price={600}
        feesDetails={{ ['Consultation fees']: 100, ['Material  fees']: 200, ['Mola fees']: 100 }}
      />

      <VaccineDisplay
        title="Pregnancy consultation"
        description="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis "
        price={250}
        feesDetails={{ ['Consultation fees']: 100 }}
      />

      <VaccineDisplay
        title="Eye consultation"
        description="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis "
        price={250}
        feesDetails={{ ['Consultation fees']: 100 }}
      />

      <div className="flex flex-row justify-between">
        <button
          type="button"
          className="button flex gap-2 mt-10"
          onClick={() => {
            goBack();
          }}
        >
          Back
        </button>
        <button
          type="button"
          className="button flex gap-2 mt-10"
          onClick={() => {
            goNext();
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

interface StepProps {
  goNext: () => void;
  goBack: () => void;
}
export default Step3;
