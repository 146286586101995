export enum ServicesActionTypes {
  SET_ERROR_MESSAGE = 'Services/SET_ERROR_MESSAGE',
  SET_IS_LOADING_CURRENT_SERVICES = 'Services/SET_IS_LOADING_CURRENT_SERVICES',
  SET_CURRENT_SERVICES = 'Services/SET_CURRENT_SERVICES',
  CREATE_SERVICE = 'Services/CREATE_SERVICE',
  DELETE_SERVICE = 'Services/DELETE_SERVICE',
  SET_IS_LOADING_DELETE_SERVICE = 'Services/SET_IS_LOADING_DELETE_SERVICE',
  UPDATE_SERVICE = 'Services/UPDATE_SERVICE',
  SET_SELECTED_SERVICE = 'Services/SET_SELECTED_SERVICE',
  SET_ERROR_DELETE_SERVICE = 'Services/SET_ERROR_DELETE_SERVICE',
}
