import React from 'react';
// import { ReactComponent as MedecineIcon2 } from '../../../assets/svg/medecine-icon-2.svg';

const MedecineDisplay: React.FC<MedecineDisplayProps> = ({
  iconImage,
  name,
  description,
  price,
}) => {
  return (
    <div className="px-2 py-3 rounded-lg bg-gray-100 w-1/3">
      {iconImage}
      {/* <MedecineIcon2 /> */}
      <p className="text-xl mt-4">{name}</p>
      <p className="mt-2 font-light text-xs text-grey-1">{description}</p>
      <p className="text-black mt-2">{price} Ksh</p>
    </div>
  );
};

interface MedecineDisplayProps {
  name: string;
  description: string;
  price: number;
  iconImage: any;
}

export default MedecineDisplay;
