import * as Yup from 'yup';

export const registerPromotionValidationSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Promotion name too short').required('Please provide the name'),
  description: Yup.string()
    .min(5, 'Promotion description too short')
    .required('Please provide a description'),
  startDate: Yup.string().min(7, 'Start date too short').required('Please provide a start date'),
  endDate: Yup.string().min(7, 'End date too short').required('Please provide an end date'),
  services: Yup.string().required('Please select services'),
  discount: Yup.number().required('Please provide discount'),
});
