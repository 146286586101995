import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { SetCurrentServicesActionI } from '../../../@types/redux/actionCreators/services';
import server from '../../../services/axios.service';
import { ServicesActionTypes } from '../types/services';
import { CreateFacilityServiceDTO } from '../../../@types/redux/appRessourceStates/services';
import store from '../..';

export const getServices: ActionCreator<
  ThunkAction<
    Promise<{
      type: string;
      payload: PaginatedItems<FacilityServiceI> | string;
    }>,
    {
      type: string;
      payload: PaginatedItems<FacilityServiceI> | string;
    },
    string,
    SetCurrentServicesActionI
  >
> = (facilityId: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: ServicesActionTypes.SET_IS_LOADING_CURRENT_SERVICES,
    payload: true,
  });

  try {
    const {
      data: { data },
    } = await server.get<ServerResponseT<PaginatedItems<FacilityServiceI>>>(
      `/facilities/${facilityId}/services`,
    );

    return dispatch({
      type: ServicesActionTypes.SET_CURRENT_SERVICES,
      payload: data,
    });
  } catch (e) {
    return dispatch({
      type: ServicesActionTypes.SET_ERROR_MESSAGE,
      payload: 'Something went wrong while fetching services',
    });
  }
};

export const createService =
  (formData: CreateFacilityServiceDTO, facilityId: string, serviceId?: string) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: ServicesActionTypes.SET_IS_LOADING_CURRENT_SERVICES,
      payload: true,
    });

    try {
      if (!serviceId) {
        const {
          data: { data },
        } = await server.post<ServerResponseT<FacilityServiceI>>(
          `/facilities/${facilityId}/services`,
          formData,
        );

        return dispatch({
          type: ServicesActionTypes.CREATE_SERVICE,
          payload: data,
        });
      }
      const { data } = await server.put<ServerResponseT<FacilityServiceI>>(
        `/facilities/${facilityId}/services/${serviceId}`,
        {
          type: formData.type,
          offeredAt: formData.offeredAt,
          description: formData.description,
          totalPrice: formData.totalPrice,
          priceDescription: formData.priceDescription,
          name: formData.name,
          instructions: formData.instructions,
          isTravelRequired: formData.isTravelRequired,
          emergencyPhoneNumber: formData.emergencyPhoneNumber,
          emergencyEmail: formData.emergencyEmail,
          collectionAtPatientResidence: formData.collectionAtPatientResidence,
          requiredAdditionalInfo: formData.requiredAdditionalInfo,
        },
      );
      console.log({ data });
      return dispatch({
        type: ServicesActionTypes.UPDATE_SERVICE,
        payload: { id: serviceId, ...formData },
      });
    } catch (e) {
      return dispatch({
        type: ServicesActionTypes.SET_ERROR_MESSAGE,
        payload: `Something went wrong while ${!serviceId ? 'creating' : 'updating'} the service`,
      });
    }
  };

export const deleteService = (serviceId: string | undefined) => async (dispatch: Dispatch) => {
  dispatch({
    type: ServicesActionTypes.SET_IS_LOADING_DELETE_SERVICE,
    payload: true,
  });

  try {
    await server.delete<ServerResponseT<FacilityServiceI>>(
      `/facilities/${store.getState().registrationState.currentFacility?.id}/services/${serviceId}`,
      { data: { data: 'data' } },
    );
    return dispatch({
      type: ServicesActionTypes.DELETE_SERVICE,
      payload: serviceId,
    });
  } catch (e) {
    dispatch({
      type: ServicesActionTypes.SET_ERROR_DELETE_SERVICE,
    });
    return dispatch({
      type: ServicesActionTypes.SET_ERROR_MESSAGE,
      payload: 'Something went wrong while deleting the service',
    });
  }
};

export const setSelectedService = (service: FacilityServiceI) => (dispatch: Dispatch) => {
  dispatch({
    type: ServicesActionTypes.SET_SELECTED_SERVICE,
    payload: service,
  });
};
