import { TextAreaInput, TextInput } from '@mola/client-components';
import { FormControlLabel, Input, Switch } from '@mui/material';
import React from 'react';

const CreateMedecine = () => {
  return (
    <div
      className="px-4 pt-4 pb-0"
      style={{
        width: 500,
      }}
    >
      <h1 className="text-xl text-dark pl-6 font-bold">New Request</h1>
      <div className="p-6">
        <h3 className="text-primary text-xl pb-2">
          <span className="">Medecine details</span>
        </h3>
        <TextInput
          labelKey="Medecine name "
          forText="medicine"
          name="medicine"
          isRequired
          classes={{}}
        />
        <TextAreaInput
          labelKey="Medecine Description "
          forText="description"
          name="description"
          isRequired
          classes={{}}
        />
        <TextAreaInput
          labelKey="How to use "
          forText="description"
          name="description"
          isRequired
          classes={{}}
        />

        <TextAreaInput
          labelKey="Precaution "
          forText="description"
          name="description"
          isRequired
          classes={{}}
        />

        <div className="mt-4 w-full">
          <label
            htmlFor="facility-category"
            className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
          >
            Upload Medecine Picture
          </label>

          <Input type="file" name="updoadfile" className="w-full" />
        </div>

        <div className="mt-4 w-full">
          <FormControlLabel
            control={<Switch />}
            label="Does the medecine require Perscription ?"
            labelPlacement="start"
            className="text-dark-3 flex flex-row justify-between"
            sx={{ display: 'flex', marginLeft: '0' }}
          />
        </div>

        <TextInput
          labelKey="Unit price (Ksh)"
          forText="medicine"
          name="medicine"
          isRequired
          classes={{}}
        />
        <div className="mt-4 w-full flex justify-between">
          <p>Available quabtuty</p>
          <p>- 7 +</p>
        </div>

        <TextAreaInput
          labelKey="Delivery Charges (Ksh) "
          forText="description"
          name="description"
          isRequired
          classes={{}}
        />

        <div className="flex flex-row justify-between">
          <span>&nbsp;</span>
          <button type="button" className="button flex gap-2 mt-10" onClick={() => {}}>
            Add Medecine
          </button>
        </div>
      </div>
    </div>
  );
};
export default CreateMedecine;
