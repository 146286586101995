export interface ModalsState {
  currentModal: { type: ModalsOptions | null; minWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' };
}

export enum ModalsOptions {
  SUBMIT_REQUEST = 'SUBMIT_REQUEST',
  REQUEST_DETAILS = 'REQUEST_DETAILS',
  CREATE_REQUEST = 'CREATE_REQUEST',
  ADD_PAYMENT_CHANNEL = 'ADD_PAYMENT_CHANNEL',
  EDIT_FACILITY = 'EDIT_FACILITY',
  CREATE_SERVICE = 'CREATE_SERVICE',
  CREATE_MEDECINE = 'CREATE_MEDECINE',
  ADD_PRACTITIONER = 'ADD_PRACTITIONER',
  EDIT_PATIENT = 'EDIT_PATIENT',
  ADD_PROMOTION = 'ADD_PROMOTION',
  DELETE_SERVICE = 'DELETE_SERVICE',
}
