import { combineReducers } from 'redux';
import { RegistrationActionTypes } from '../actions/types/registration';
import initialState from '../initialState';
import { registrationReducer } from './registration';
import { modalsReducer } from './modals';
import { AppCookies } from '../../@types/redux';

import { uiReducer } from './ui';
import { requestsReducer } from './requests';
import { servicesReducer } from './services';
import { practitionersReducer } from './practitioners';

const appReducer = combineReducers<StoreI>({
  registrationState: registrationReducer,
  modalsState: modalsReducer,
  uiState: uiReducer,
  requestsState: requestsReducer,
  servicesState: servicesReducer,
  practitionersState: practitionersReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === RegistrationActionTypes.LOGOUT) {
    // Clearing the storage
    localStorage.removeItem(AppCookies.accessToken);
    sessionStorage.removeItem(AppCookies.accessToken);
    localStorage.removeItem(AppCookies.refreshToken);
    sessionStorage.removeItem(AppCookies.refreshToken);

    location.href = `${process.env.REACT_APP_AUTH_PORTAL_URL}/auth/redirect/?&logout=true`;

    return appReducer(initialState, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
