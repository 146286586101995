import { Reducer } from 'redux';
import initialState from '../initialState';
import { PractitionersState } from '../../@types/redux/appRessourceStates/practitioners';
import { PractitionersActionTypes } from '../actions/types/practitioners';
import {
  PractitionersActions,
  SetIsLoadingPractitionersActionI,
  SetPractitionersActionI,
  SetCurrentErrorActionI,
} from '../../@types/redux/actionCreators/practitioners';
import { toast } from 'react-toastify';

export const practitionersReducer: Reducer<PractitionersState, PractitionersActions> = (
  state = initialState.practitionersState,
  action,
): PractitionersState => {
  switch (action.type) {
    case PractitionersActionTypes.SET_IS_LOADING_PRACTITIONERS:
      return {
        ...state,
        isLoadingPractitioners: (action as SetIsLoadingPractitionersActionI).payload,
      };

    case PractitionersActionTypes.SET_PRACTITIONERS:
      return {
        ...state,
        isLoadingPractitioners: false,
        practitioners: (action as SetPractitionersActionI).payload,
      };

    case PractitionersActionTypes.SET_CURRENT_ERROR:
      toast.error((action as SetCurrentErrorActionI).payload);

      return {
        ...state,
        isLoadingPractitioners: false,
        currentError: (action as SetCurrentErrorActionI).payload,
      };

    default:
      return state;
  }
};
