import { Dispatch } from 'redux';
import { PractitionersActionTypes } from '../types/practitioners';
import server from '../../../services/axios.service';

export const getPractionners = (query?: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: PractitionersActionTypes.SET_IS_LOADING_PRACTITIONERS,
    payload: true,
  });

  try {
    const {
      data: { data },
    } = await server.get<ServerResponseT<PaginatedItems<PractitionerI>>>(
      `/practitioners?${query ? 'name=' + query : ''}`,
    );
    return dispatch({
      type: PractitionersActionTypes.SET_PRACTITIONERS,
      payload: data,
    });
  } catch (e) {
    return dispatch({
      type: PractitionersActionTypes.SET_CURRENT_ERROR,
      payload: 'Something went wrong while getting the practitioners: ' + JSON.stringify(e),
    });
  }
};
