import React from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
// import Step4 from './Step4';

enum NewPractitionerSteps {
  PRIMARY_DETAILS = 'PRIMARY_DETAILS',
  SERVICES_DETAILS = 'SERVICES_DETAILS',
  SCHEDULE_DETAILS = 'SCHEDULE_DETAILS',
}

const Index: React.FC = () => {
  const [currentStep, setCurrentStep] = React.useState(NewPractitionerSteps.PRIMARY_DETAILS);

  const getStep = () => {
    switch (currentStep) {
      case NewPractitionerSteps.PRIMARY_DETAILS:
        return (
          <Step1
            goNext={() => {
              setCurrentStep(NewPractitionerSteps.SERVICES_DETAILS);
            }}
          />
        );

      case NewPractitionerSteps.SERVICES_DETAILS:
        return (
          <Step2
            goNext={() => {
              setCurrentStep(NewPractitionerSteps.SCHEDULE_DETAILS);
            }}
            goBack={() => {
              setCurrentStep(NewPractitionerSteps.PRIMARY_DETAILS);
            }}
          />
        );

      case NewPractitionerSteps.SCHEDULE_DETAILS:
        return (
          <Step3
            goNext={() => {
              setCurrentStep(NewPractitionerSteps.SCHEDULE_DETAILS);
            }}
            goBack={() => {
              setCurrentStep(NewPractitionerSteps.SERVICES_DETAILS);
            }}
          />
        );
    }
  };
  return (
    <div
      className="px-4 pt-4 pb-0"
      style={{
        width: 500,
      }}
    >
      <h1 className="text-xl text-dark pl-6 font-bold">Add Practitioner</h1>
      {getStep()}
    </div>
  );
};
export default Index;
