import React, { useState } from 'react';
import { ReactComponent as Step1Icon } from '../../../assets/svg/2steps-stepper-1-of-2.svg';
import { Select } from '@mui/material';
import { ImagePicker, TextInput } from '@mola/client-components';
import { ReactComponent as ImageUploadSvg } from '../../../assets/svg/image-upload-svg.svg';
import { ReactComponent as ImagePickerIcon } from '../../../assets/svg/image-picker-icon.svg';

const Step1: React.FC<StepProps> = ({ goNext }) => {
  const [, setImage] = useState<File>();
  return (
    <div className="p-6">
      <h3 className="flex flex-row justify-between gap-20 text-primary text-xl pb-2">
        <span className="">Personal information</span> <Step1Icon className="mt-2 " />
      </h3>
      <div className="mt-6">
        <p className="font-bold text-dark-1 mb-2">Profile picture</p>
        <ImagePicker
          onImageUpload={(imageFile) => {
            setImage(imageFile);
          }}
          ImageUploadSvg={ImageUploadSvg}
          ImagePickerIcon={ImagePickerIcon}
        />
      </div>
      <TextInput
        labelKey="First Name"
        forText="firstName"
        name="firstName"
        isRequired
        classes={{}}
      />
      <TextInput labelKey="Last Name" forText="lastName" name="lastName" isRequired classes={{}} />
      <TextInput labelKey="Email" forText="email" name="email" isRequired classes={{}} />
      <TextInput
        labelKey="Phone Number"
        forText="phone"
        name="phone"
        isRequired={false}
        classes={{}}
      />
      <div className="flex gap-4">
        <TextInput
          labelKey="Date of birth"
          forText="dateBirth"
          name="dateBirth"
          isRequired={false}
          classes={{}}
        />
        <div className="mt-4 w-1/2">
          <label
            htmlFor="facility-category"
            className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
          >
            Gender
          </label>
          <Select name="gender" id="user-gender" className="rounded-md h-10 mt-2 w-full px-1">
            <option value="certificate" key={0} className="hover:bg-grey-4 cursor-pointer">
              Select
            </option>
          </Select>
        </div>
      </div>
      <TextInput
        labelKey="Residental adress"
        forText="residental"
        name="residental"
        isRequired={false}
        classes={{}}
      />
      <TextInput
        labelKey="Workplace adress"
        forText="workplace"
        name="workplace"
        isRequired={false}
        classes={{}}
      />
      <div className="flex flex-row justify-between">
        <span>&nbsp;</span>
        <button
          type="button"
          className="button flex gap-2 mt-10"
          onClick={() => {
            goNext();
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

interface StepProps {
  goNext: () => void;
}

export default Step1;
