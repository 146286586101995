import { Select } from '@mui/material';
import React from 'react';
import { TextInput } from '@mola/client-components';

const AddPaymentChannel: React.FC = () => {
  return (
    <div
      className="px-4 pt-4 pb-0"
      style={{
        width: 500,
      }}
    >
      <h1 className="text-xl text-dark pl-6 font-bold">Add payment Channel</h1>

      <div className="p-6">
        <h3 className="flex flex-row justify-between gap-20 text-primary text-xl pb-2">
          <span className="">Channel details</span>
        </h3>

        <div className="mt-4 w-full">
          <label
            htmlFor="facility-category"
            className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
          >
            Type <span className="text-gray-400">*</span>
          </label>
          <Select name="gender" id="user-gender" className="rounded-md h-10 mt-2 w-full px-1">
            <option value="male" key={0} className="hover:bg-grey-4 cursor-pointer">
              Debit Cart
            </option>
            <option value="female" key={1} className="hover:bg-grey-4 cursor-pointer">
              Visa
            </option>
          </Select>
        </div>

        <TextInput labelKey="Account No" forText="account" name="account" isRequired classes={{}} />

        <div className="flex flex-row justify-between">
          <span>&nbsp;</span>
          <button type="button" className="button flex gap-2 mt-10" onClick={() => {}}>
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddPaymentChannel;
