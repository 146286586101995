import React from 'react';
import { ModalsOptions } from '../../../../@types/redux/appRessourceStates/modals';
import RequestDetailsModal from '../../../Requests/DetailsModal/RequestDetailsModal';
import NewConsultationRequest from '../../../Requests/newRequestModal';
import AddPaymentChannel from '../../../Settings/SettingsModals/AddPaymentChannel';
import EditFacility from '../../../Settings/SettingsModals/EditFacility';
import CreateService from '../../../Services/CreateService';
import CreateMedecine from '../../../Services/CreateMedecine';
import NewPractitioner from '../../../Practitioners/newPractitionerModal';
import NewPatient from '../../../Patients/newPatientModal';
import Promotion from '../../../Payments/Promotion';
import DeleteService from '../../../Services/DetailsModal/DeleteServiceModal';

export function modalsMatcher(currentModal: ModalsOptions | null) {
  switch (currentModal) {
    case ModalsOptions.REQUEST_DETAILS:
      return <RequestDetailsModal />;
    case ModalsOptions.CREATE_REQUEST:
      return <NewConsultationRequest />;
    case ModalsOptions.ADD_PAYMENT_CHANNEL:
      return <AddPaymentChannel />;
    case ModalsOptions.EDIT_FACILITY:
      return <EditFacility />;
    case ModalsOptions.CREATE_SERVICE:
      return <CreateService />;
    case ModalsOptions.CREATE_MEDECINE:
      return <CreateMedecine />;
    case ModalsOptions.ADD_PRACTITIONER:
      return <NewPractitioner />;
    case ModalsOptions.EDIT_PATIENT:
      return <NewPatient />;
    case ModalsOptions.ADD_PROMOTION:
      return <Promotion />;
    case ModalsOptions.DELETE_SERVICE:
      return <DeleteService />;
    default:
      return null;
  }
}

export default modalsMatcher;
