import * as React from 'react';
import { ListItem, ListItemButton, ListItemText, Box, LinearProgress } from '@mui/material';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

const PractitionerListPopover: React.FC<PractitionerListPopoverProps> = (props) => {
  return (
    <Box sx={{ width: '100%', height: 300, maxWidth: 360, bgcolor: 'background.paper' }}>
      {props.loading && (
        <div className="mb-2 mx-auto">
          <LinearProgress />
        </div>
      )}
      {!props.loading && !props.items?.length && (
        <div className="flex justify-center items-center mt-9">
          <span>No practitioner available</span>
        </div>
      )}
      <FixedSizeList
        height={300}
        width={300}
        itemSize={46}
        itemCount={props.items?.length || 0}
        itemData={{ items: props.items, onSelected: props.onSelected }}
      >
        {(childData: ListChildComponentProps) => {
          const { data, index, style } = childData;
          const { items, onSelected } = data;

          const item = items[index] as PractitionerI;

          return (
            <ListItem style={style} key={index} component="div" divider disablePadding>
              <ListItemButton
                onClick={() => {
                  onSelected(item);
                }}
              >
                <ListItemText primary={`${item.user.firstName} ${item.user.lastName}`} />
              </ListItemButton>
            </ListItem>
          );
        }}
      </FixedSizeList>
    </Box>
  );
};

interface PractitionerListPopoverProps {
  items: PractitionerI[];
  onSelected: (practitioner: PractitionerI) => void;
  loading: boolean;
}

export default PractitionerListPopover;
