import React from 'react';
import { ReactComponent as Step3Icon } from '../../../assets/svg/3steps-stepper-3-of-3.svg';
import { Checkbox, FormControlLabel } from '@mui/material';

const Step3: React.FC<StepProps> = ({ goNext, goBack }) => {
  return (
    <div className="p-6">
      <h3 className="flex flex-row justify-between gap-20 text-primary text-xl pb-2">
        <span className="">Schedule</span> <Step3Icon className="mt-2 " />
      </h3>
      <div className="mt-4 w-full">
        <div className="mt-2 flex justify-between items-center">
          <FormControlLabel
            value="mondany"
            control={<Checkbox defaultChecked />}
            label="Monday"
            className="text-grey"
          />
          <span className="text-grey">08:00- 17:00</span>
        </div>
        <div className="mt-2 flex justify-between items-center">
          <FormControlLabel
            value="tuesday "
            control={<Checkbox defaultChecked />}
            label="Tuesday "
            className="text-grey"
          />
          <span className="text-grey">08:00- 17:00</span>
        </div>
        <div className="mt-2 flex justify-between items-center">
          <FormControlLabel
            value="wednesday "
            control={<Checkbox defaultChecked />}
            label="Wednesday "
            className="text-grey"
          />
          <span className="text-grey">08:00- 17:00</span>
        </div>
        <div className="mt-2 flex justify-between items-center">
          <FormControlLabel
            value="thursday "
            control={<Checkbox defaultChecked />}
            label="Thursday "
            className="text-grey"
          />
          <span className="text-grey">08:00- 17:00</span>
        </div>
        <div className="mt-2 flex justify-between items-center">
          <FormControlLabel
            value="friday  "
            control={<Checkbox defaultChecked />}
            label="Friday  "
            className="text-grey"
          />
          <span className="text-grey">08:00- 17:00</span>
        </div>
        <div className="mt-2 flex justify-between items-center">
          <FormControlLabel
            value="saturday   "
            control={<Checkbox />}
            label="Saturday   "
            className="text-grey"
          />
          <span className="text-grey-1 underline cursor-pointer">Add time</span>
        </div>
        <div className="mt-2 flex justify-between items-center">
          <FormControlLabel
            value="sunday   "
            control={<Checkbox />}
            label="Sunday   "
            className="text-grey"
          />
          <span className="text-grey-1 underline cursor-pointer">Add time</span>
        </div>
      </div>

      <div className="flex flex-row justify-between">
        <button
          type="button"
          className="button flex gap-2 mt-10"
          onClick={() => {
            goBack();
          }}
        >
          Back
        </button>
        <button
          type="button"
          className="button flex gap-2 mt-10"
          onClick={() => {
            goNext();
          }}
        >
          Add Practitioner
        </button>
      </div>
    </div>
  );
};

interface StepProps {
  goNext: () => void;
  goBack: () => void;
}

export default Step3;
