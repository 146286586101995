import { Reducer } from 'redux';
import initialState from '../initialState';
import { UiState } from '../../@types/redux';
import { SetCurrentErrorActionI, UiActions } from '../../@types/redux/actionCreators/ui';
import { UiActionTypes } from '../actions/types/ui';
import { toast } from 'react-toastify';

export const uiReducer: Reducer<UiState, UiActions> = (state = initialState.uiState, action) => {
  switch (action.type) {
    case UiActionTypes.SET_CURRENT_ERROR:
      toast.error((action as SetCurrentErrorActionI).payload, { autoClose: 3000 });
      return {
        ...state,
        errorMessage: (action as SetCurrentErrorActionI).payload,
      };

    default:
      return state;
  }
};
