import React from 'react';
import Step1 from './Step1';
import Step2 from './Step2';

enum NewPatientSteps {
  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
  FOLLOW_UP_PROGRAM = 'FOLLOW_UP_PROGRAM',
}

const Index: React.FC = () => {
  const [currentStep, setCurrentStep] = React.useState(NewPatientSteps.PERSONAL_INFORMATION);

  const getStep = () => {
    switch (currentStep) {
      case NewPatientSteps.PERSONAL_INFORMATION:
        return (
          <Step1
            goNext={() => {
              setCurrentStep(NewPatientSteps.FOLLOW_UP_PROGRAM);
            }}
          />
        );

      case NewPatientSteps.FOLLOW_UP_PROGRAM:
        return (
          <Step2
            goNext={() => {
              setCurrentStep(NewPatientSteps.FOLLOW_UP_PROGRAM);
            }}
            goBack={() => {
              setCurrentStep(NewPatientSteps.PERSONAL_INFORMATION);
            }}
          />
        );
    }
  };
  return (
    <div
      className="px-4 pt-4 pb-0"
      style={{
        width: 500,
      }}
    >
      <h1 className="text-xl text-dark pl-6 font-bold">Edit Profile</h1>
      {getStep()}
    </div>
  );
};
export default Index;
