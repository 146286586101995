import React from 'react';
import { ReactComponent as Step1Icon } from '../../../assets/svg/4StepsSterpper-step4.svg';
import { ReactComponent as MedecineIcon1 } from '../../../assets/svg/medecine-icon-1.svg';
import { ReactComponent as MedecineIcon2 } from '../../../assets/svg/medecine-icon-2.svg';
import MedecineDisplay from './MedecineDisplay';
import { SearchInput } from '@mola/client-components';
import { ReactComponent as SearchIcon } from '../../../assets/svg/search-icon.svg';

const SelectMedecineStep: React.FC<StepProps> = ({ goNext, goBack }) => {
  return (
    <div className="p-6">
      <h3 className="flex flex-row justify-between gap-20 text-primary text-xl pb-2">
        <span className="">Select Medecine</span> <Step1Icon className="mt-2 " />
      </h3>
      <SearchInput
        labelKey="Search medecine (optional)"
        forText="search"
        name="lastName"
        isRequired={false}
        classes={{}}
        placeHolder="Search Mola ID"
        onSearchClicked={() => {}}
        getSearchIcon={() => <SearchIcon />}
      />
      <div className="my-4 flex justify-between">
        <span>2 Items Selected</span>
        <span className="text-secondary">Clear All</span>
      </div>

      <div className="flex gap-x-2">
        <MedecineDisplay
          iconImage={<MedecineIcon1 />}
          name="Probiotics"
          description="Lorem ipsum dolor sit
amet, consectetuer "
          price={1500}
        />
        <MedecineDisplay
          iconImage={<MedecineIcon2 />}
          name="proactive"
          description="Lorem ipsum dolor sit
amet, consectetuer "
          price={1500}
        />
        <MedecineDisplay
          iconImage={<MedecineIcon2 />}
          name="Umzu"
          description="Lorem ipsum dolor sit
amet, consectetuer "
          price={500}
        />
      </div>

      <div className="my-4 flex justify-between">
        <span>Beauty & Skin care</span>
        <span className="text-secondary">View all</span>
      </div>

      <div className="flex gap-x-2">
        <MedecineDisplay
          iconImage={<MedecineIcon1 />}
          name="Probiotics"
          description="Lorem ipsum dolor sit
amet, consectetuer "
          price={1500}
        />
        <MedecineDisplay
          iconImage={<MedecineIcon2 />}
          name="proactive"
          description="Lorem ipsum dolor sit
amet, consectetuer "
          price={1500}
        />
        <MedecineDisplay
          iconImage={<MedecineIcon2 />}
          name="Umzu"
          description="Lorem ipsum dolor sit
amet, consectetuer "
          price={500}
        />
      </div>

      <div className="my-4 flex justify-between">
        <span>Personal care</span>
        <span className="text-secondary">View all</span>
      </div>

      <div className="flex gap-x-2">
        <MedecineDisplay
          iconImage={<MedecineIcon1 />}
          name="Probiotics"
          description="Lorem ipsum dolor sit
amet, consectetuer "
          price={1500}
        />
        <MedecineDisplay
          iconImage={<MedecineIcon2 />}
          name="proactive"
          description="Lorem ipsum dolor sit
amet, consectetuer "
          price={1500}
        />
        <MedecineDisplay
          iconImage={<MedecineIcon2 />}
          name="Umzu"
          description="Lorem ipsum dolor sit
amet, consectetuer "
          price={500}
        />
      </div>

      <div className="flex flex-row justify-between">
        <button
          type="button"
          className="button flex gap-2 mt-10"
          onClick={() => {
            goBack();
          }}
        >
          Back
        </button>
        <button
          type="button"
          className="button flex gap-2 mt-10"
          onClick={() => {
            goNext();
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};
interface StepProps {
  goNext: () => void;
  goBack: () => void;
}
export default SelectMedecineStep;
