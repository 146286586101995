import React from 'react';
import { ReactComponent as Step2Icon } from '../../../assets/svg/3steps-stepper-2-of-3.svg';
import { Select } from '@mui/material';

const Step2: React.FC<StepProps> = ({ goNext, goBack }) => {
  return (
    <div className="p-6">
      <h3 className="flex flex-row justify-between gap-20 text-primary text-xl pb-2">
        <span className="">Services</span> <Step2Icon className="mt-2 " />
      </h3>
      <div className="mt-4 w-full">
        <label
          htmlFor="facility-category"
          className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
        >
          Practitioner Role
        </label>
        <Select name="gender" id="user-gender" className="rounded-md h-10 mt-2 w-full px-1" native>
          <option key={Math.random()} className="hover:bg-grey-4 cursor-pointer px-2 py-1">
            Doctor
          </option>
          <option key={Math.random()} className="hover:bg-grey-4 cursor-pointer px-2 py-1">
            Nurse
          </option>
          <option key={Math.random()} className="hover:bg-grey-4 cursor-pointer px-2 py-1">
            Clinician
          </option>
          <option key={Math.random()} className="hover:bg-grey-4 cursor-pointer px-2 py-1">
            Phlebotomist
          </option>
          <option key={Math.random()} className="hover:bg-grey-4 cursor-pointer px-2 py-1">
            Ambulance driver
          </option>
          <option key={Math.random()} className="hover:bg-grey-4 cursor-pointer px-2 py-1">
            Delivery driver
          </option>
          <option key={Math.random()} className="hover:bg-grey-4 cursor-pointer px-2 py-1">
            Pharmacist
          </option>
        </Select>
      </div>
      <div className="mt-4 w-full">
        <label
          htmlFor="facility-category"
          className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
        >
          Select service (s) <span className="text-gray-400">*</span>
        </label>
        <Select name="gender" id="user-gender" className="rounded-md h-10 mt-2 w-full px-1" native>
          <option key={Math.random()} className="hover:bg-grey-4 cursor-pointer px-2 py-1">
            All
          </option>
          <option key={Math.random()} className="hover:bg-grey-4 cursor-pointer px-2 py-1">
            Consultation
          </option>
          <option key={Math.random()} className="hover:bg-grey-4 cursor-pointer px-2 py-1">
            Lab request
          </option>
          <option key={Math.random()} className="hover:bg-grey-4 cursor-pointer px-2 py-1">
            Pharmacy
          </option>
          <option key={Math.random()} className="hover:bg-grey-4 cursor-pointer px-2 py-1">
            Vaccination
          </option>
          <option key={Math.random()} className="hover:bg-grey-4 cursor-pointer px-2 py-1">
            Blood Donation
          </option>
        </Select>
      </div>
      <div className="mt-4 w-full">
        <label
          htmlFor="facility-category"
          className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
        >
          Available for
        </label>
        <Select name="gender" id="user-gender" className="rounded-md h-10 mt-2 w-full px-1" native>
          <option key={Math.random()} className="hover:bg-grey-4 cursor-pointer px-2 py-1">
            All
          </option>
          <option key={Math.random()} className="hover:bg-grey-4 cursor-pointer px-2 py-1">
            Remote
          </option>
          <option key={Math.random()} className="hover:bg-grey-4 cursor-pointer px-2 py-1">
            At Facility
          </option>
          <option key={Math.random()} className="hover:bg-grey-4 cursor-pointer px-2 py-1">
            Out of facility
          </option>
        </Select>
      </div>
      <div className="flex flex-row justify-between">
        <button
          type="button"
          className="button flex gap-2 mt-10"
          onClick={() => {
            goBack();
          }}
        >
          Back
        </button>
        <button
          type="button"
          className="button flex gap-2 mt-10"
          onClick={() => {
            goNext();
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

interface StepProps {
  goNext: () => void;
  goBack: () => void;
}

export default Step2;
