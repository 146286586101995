import React from 'react';
import { FacilityServiceType } from '@mola/client-components';
import BloodDonationFinalStep from './BloodDonationFinalStep';
import EmergencyFinalStep from './EmergencyFinalStep';
import SelectMedecineStep from './SelectMedecineStep';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';

enum NewConsultationRequestSteps {
  PATIENT_DETAILS = 'PATIENT_DETAILS',
  REQUEST_DETAILS = 'REQUEST_DETAILS',
  PACKAGE_DETAILS = 'PACKAGE_DETAILS',
  PAYMENT_DETAILS = 'PAYMENT_DETAILS',
  RESULT_DETAILS = 'RESULT_DETAILS',
  SELECT_MEDECINE = 'SELECT_MEDECINE',
  BLOOD_DONATION = 'BLOOD_DONATION',
  EMERGENCY = 'EMERGENCY',
}

const NewConsultationRequest = () => {
  const [currentStep, setCurrentStep] = React.useState(NewConsultationRequestSteps.PATIENT_DETAILS);
  const getStep = () => {
    switch (currentStep) {
      case NewConsultationRequestSteps.PATIENT_DETAILS:
        return (
          <Step1
            goNext={() => {
              setCurrentStep(NewConsultationRequestSteps.REQUEST_DETAILS);
            }}
          />
        );
      case NewConsultationRequestSteps.REQUEST_DETAILS:
        return (
          <Step2
            goNext={(service) => {
              if (service === FacilityServiceType.PHARMACY) {
                setCurrentStep(NewConsultationRequestSteps.SELECT_MEDECINE);
              } else if (service === FacilityServiceType.BLOOD_REQUEST) {
                setCurrentStep(NewConsultationRequestSteps.BLOOD_DONATION);
              } else if (service === FacilityServiceType.EMERGENCY) {
                setCurrentStep(NewConsultationRequestSteps.EMERGENCY);
              } else {
                setCurrentStep(NewConsultationRequestSteps.PACKAGE_DETAILS);
              }
            }}
            goBack={() => {
              setCurrentStep(NewConsultationRequestSteps.PATIENT_DETAILS);
            }}
          />
        );
      case NewConsultationRequestSteps.PACKAGE_DETAILS:
        return (
          <Step3
            goNext={() => {
              setCurrentStep(NewConsultationRequestSteps.PAYMENT_DETAILS);
            }}
            goBack={() => {
              setCurrentStep(NewConsultationRequestSteps.REQUEST_DETAILS);
            }}
          />
        );
      case NewConsultationRequestSteps.PAYMENT_DETAILS:
        return (
          <Step4
            goNext={() => {
              // setCurrentStep(NewConsultationRequestSteps.RESULT_DETAILS);
            }}
            goBack={() => {
              setCurrentStep(NewConsultationRequestSteps.PACKAGE_DETAILS);
            }}
          />
        );

      case NewConsultationRequestSteps.RESULT_DETAILS:
        return (
          <Step5
            goNext={() => {
              // setCurrentStep(NewConsultationRequestSteps.RESULT_DETAILS);
            }}
            goBack={() => {
              setCurrentStep(NewConsultationRequestSteps.PAYMENT_DETAILS);
            }}
          />
        );

      case NewConsultationRequestSteps.SELECT_MEDECINE:
        return (
          <SelectMedecineStep
            goNext={() => {
              setCurrentStep(NewConsultationRequestSteps.PAYMENT_DETAILS);
            }}
            goBack={() => {
              setCurrentStep(NewConsultationRequestSteps.REQUEST_DETAILS);
            }}
          />
        );

      case NewConsultationRequestSteps.BLOOD_DONATION:
        return (
          <BloodDonationFinalStep
            goNext={() => {
              // setCurrentStep(NewConsultationRequestSteps.PAYMENT_DETAILS);
            }}
            goBack={() => {
              setCurrentStep(NewConsultationRequestSteps.REQUEST_DETAILS);
            }}
          />
        );

      case NewConsultationRequestSteps.EMERGENCY:
        return (
          <EmergencyFinalStep
            goNext={() => {
              // setCurrentStep(NewConsultationRequestSteps.PAYMENT_DETAILS);
            }}
            goBack={() => {
              setCurrentStep(NewConsultationRequestSteps.REQUEST_DETAILS);
            }}
          />
        );
    }
  };

  return (
    <div
      className="px-4 pt-4 pb-0"
      style={{
        width: 500,
      }}
    >
      <h1 className="text-xl text-dark pl-6 font-bold">New Request</h1>
      {getStep()}
    </div>
  );
};
export default NewConsultationRequest;
