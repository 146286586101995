import React, { useState } from 'react';
import { Formik } from 'formik';
import { registerPromotionValidationSchema } from '../../shared/schemas/promotion';
import {
  InputChangeState,
  TextAreaInput,
  TextInput,
  TextInputType,
  SearchInput,
} from '@mola/client-components';
import { ReactComponent as SearchIcon } from '../../assets/svg/search-icon.svg';

const initialState = {
  name: '',
  description: '',
  startDate: '',
  endDate: '',
  services: '',
  discount: '',
};

const Promotion: React.FC = () => {
  const [isLoading] = useState(false);
  return (
    <Formik
      onSubmit={() => {}}
      validateOnChange
      initialValues={initialState}
      validationSchema={registerPromotionValidationSchema}
    >
      {(props) => {
        const { touched, errors, isValid, handleChange, handleBlur, values } = props;

        const isFormTouched = Object.values(touched).length > 0;
        const isFormValid = isValid && !errors.name && isFormTouched;

        return (
          <div
            className="px-4 pt-4 pb-0"
            style={{
              width: 500,
            }}
          >
            <h1 className="text-xl text-dark pl-6 font-bold">Add Promotion</h1>
            <div className="p-6">
              <h3 className="flex flex-row justify-between gap-20 text-primary text-xl pb-2">
                <span className="">Promotion details</span>
              </h3>

              <TextInput
                labelKey="Promotion name"
                forText="name"
                name="name"
                isRequired
                onChange={(value, event) => {
                  handleChange(event);
                }}
                onBlur={handleBlur}
                changeState={
                  touched.name && errors.name ? InputChangeState.INVALID : InputChangeState.VALID
                }
                errorMessage={errors.name}
                value={values.name}
                disabled={isLoading}
                classes={{}}
              />

              <TextAreaInput
                labelKey="Promotion Description"
                forText="description"
                name="description"
                isRequired
                onChange={(value, event) => {
                  handleChange(event);
                }}
                onBlur={handleBlur}
                changeState={
                  touched.description && errors.description
                    ? InputChangeState.INVALID
                    : InputChangeState.VALID
                }
                errorMessage={errors.description}
                value={values.description}
                disabled={isLoading}
                classes={{}}
              />

              <div className="flex justify-between gap-2">
                <TextInput
                  labelKey="Start Date"
                  forText="startDate"
                  name="startDate"
                  type="date"
                  isRequired
                  onChange={(value, event) => {
                    handleChange(event);
                  }}
                  onBlur={handleBlur}
                  changeState={
                    touched.startDate && errors.startDate
                      ? InputChangeState.INVALID
                      : InputChangeState.VALID
                  }
                  errorMessage={errors.startDate}
                  value={values.startDate}
                  disabled={isLoading}
                  classes={{}}
                />
                <TextInput
                  labelKey="End Date"
                  forText="endDate"
                  name="endDate"
                  isRequired
                  type="date"
                  onChange={(value, event) => {
                    handleChange(event);
                  }}
                  onBlur={handleBlur}
                  changeState={
                    touched.endDate && errors.endDate
                      ? InputChangeState.INVALID
                      : InputChangeState.VALID
                  }
                  errorMessage={errors.endDate}
                  value={values.endDate}
                  disabled={isLoading}
                  classes={{}}
                />
              </div>

              <SearchInput
                labelKey="Search Services/Packages"
                forText="services"
                name="services"
                isRequired
                onChange={(value, event) => {
                  handleChange(event);
                }}
                onBlur={handleBlur}
                changeState={
                  touched.services && errors.services
                    ? InputChangeState.INVALID
                    : InputChangeState.VALID
                }
                errorMessage={errors.services}
                value={values.services}
                disabled={isLoading}
                classes={{}}
                onSearchClicked={() => {}}
                getSearchIcon={() => <SearchIcon />}
              />

              <TextInput
                labelKey="Discount (%)"
                forText="discount"
                name="discount"
                isRequired
                type={TextInputType.NUMBER}
                onChange={(value, event) => {
                  handleChange(event);
                }}
                onBlur={handleBlur}
                changeState={
                  touched.discount && errors.discount
                    ? InputChangeState.INVALID
                    : InputChangeState.VALID
                }
                errorMessage={errors.discount}
                value={values.discount}
                disabled={isLoading}
                classes={{}}
              />

              <div className="flex flex-row justify-between">
                <span>&nbsp;</span>
                <button
                  type="button"
                  className={`button flex gap-2 mt-10 ${
                    !isFormValid || isLoading ? 'bg-primary-light c-no-drop' : ''
                  }`}
                  disabled={!isFormValid}
                >
                  Add Promotion
                </button>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default Promotion;
