import React from 'react';
import { ReactComponent as Step1Icon } from '../../../assets/svg/4StepsSterpper-step4.svg';
import { ReactComponent as BloodIcon } from '../../../assets/svg/blood-donation-icon.svg';
import { CheckboxInput, SearchInput } from '@mola/client-components';
import { ReactComponent as SearchIcon } from '../../../assets/svg/search-icon.svg';

const BloodDonationFinalStep: React.FC<StepProps> = ({ goNext, goBack }) => {
  return (
    <div className="p-6">
      <h3 className="flex flex-row justify-between gap-20 text-primary text-xl pb-2">
        <span className="">Blood donation details</span> <Step1Icon className="mt-2 " />
      </h3>
      <SearchInput
        labelKey="Search campaign"
        forText="search"
        name="lastName"
        isRequired={false}
        classes={{}}
        placeHolder="Search campaign"
        onSearchClicked={() => {}}
        getSearchIcon={() => <SearchIcon />}
      />

      <div className="border border-dark-4 rounded-md p-4 mt-10">
        <div className="flex flex-row justify-between items-center">
          <div className="flex gap-x-2 items-center">
            <BloodIcon />
            <p className="text-sm">Donation end date: Jan 05th 12:30 PM</p>
          </div>
          <CheckboxInput labelText="" />
        </div>
        <div className="text-sm text-grey-1 mt-4">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget
          dolor. Aenean massa. Cum sociis{' '}
        </div>
      </div>

      <div className="border border-dark-4 rounded-md p-4 mt-10">
        <div className="flex flex-row justify-between items-center">
          <div className="flex gap-x-2 items-center">
            <BloodIcon />
            <p className="text-sm">Donation end date: Jan 05th 12:30 PM</p>
          </div>
          <CheckboxInput labelText="" />
        </div>
        <div className="text-sm text-grey-1 mt-4">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget
          dolor. Aenean massa. Cum sociis{' '}
        </div>
      </div>

      <div className="flex flex-row justify-between">
        <button
          type="button"
          className="button flex gap-2 mt-10"
          onClick={() => {
            goBack();
          }}
        >
          Back
        </button>
        <button
          type="button"
          className="button flex gap-2 mt-10"
          onClick={() => {
            goNext();
          }}
        >
          Crete request
        </button>
      </div>
    </div>
  );
};

interface StepProps {
  goNext: () => void;
  goBack: () => void;
}

export default BloodDonationFinalStep;
