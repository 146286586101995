import { FormControlLabel, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  FacilityOfferedAtOptions,
  FacilityServiceType,
  TextAreaInput,
  TextInput,
} from '@mola/client-components';
import { CreateFacilityServiceDTO } from '../../../@types/redux/appRessourceStates/services';
import { ModalsState } from '../../../@types/redux/appRessourceStates/modals';
import { createService } from '../../../store/actions/creators/services';
import { setCurrentModalType } from '../../../store/actions/creators/modals';
import { ReactComponent as Vector1 } from '../../../assets/svg/blood-request-vector-1.svg';
import { ReactComponent as Vector2 } from '../../../assets/svg/blood-request-vector-2.svg';

const BloodDonation: React.FC<BloodDonationProps> = ({
  currentFacility,
  _createService,
  isLoadingCurrentFacility,
  _setCurrentModalType,
  selectedService,
}) => {
  const [requireInfos, setrequireInfos] = useState(false);
  const [extraInfos, setExtraInfos] = useState({
    location: '',
    ageAbove17: false,
    weightAbove50: false,
    heightAbove5: false,
    bloodGroup: false,
    donatedBefore: false,
    regularMedecine: false,
    smoke: false,
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
  });
  const [fields, setFields] = useState({
    type: FacilityServiceType.BLOOD_REQUEST,
    offeredAt: FacilityOfferedAtOptions.FACILITY,
    collectionAtPatientResidence: false,
    name: 'Blood request',
    description: '',
    instructions: '',
    totalPrice: 0,
    priceDescription: 'N/A',
    isTravelRequired: false,
    emergencyPhoneNumber: currentFacility?.phoneNumber as string,
    emergencyEmail: currentFacility?.email as string,
    requiredAdditionalInfo: 'N/A',
  });

  useEffect(() => {
    if (selectedService) {
      setFields((stateFields) => ({
        ...stateFields,
        ...selectedService,
        type: selectedService.type,
        name: selectedService.name,
        description: selectedService.description,
      }));
    }
  }, [selectedService]);

  return (
    <div>
      <TextInput
        labelKey="Main Collection location"
        forText="location"
        name="location"
        onChange={(value) => {
          setExtraInfos({ ...extraInfos, location: value });
        }}
        value={extraInfos.location}
        disabled={isLoadingCurrentFacility}
        isRequired
        classes={{}}
      />

      <div className="mt-4 w-full">
        <FormControlLabel
          control={
            <Switch
              onChange={() => {
                setFields({
                  ...fields,
                  collectionAtPatientResidence: !fields.collectionAtPatientResidence,
                });
              }}
              value={fields.collectionAtPatientResidence}
            />
          }
          label="Allow collection at patient residence ?"
          labelPlacement="start"
          className="text-dark-3 flex flex-row justify-between"
          sx={{ display: 'flex', marginLeft: '0' }}
        />
      </div>
      <div className="flex justify-between">
        <TextInput
          labelKey="Start Date"
          forText="startDate"
          name="startDate"
          onChange={(value) => {
            setExtraInfos({ ...extraInfos, startDate: value });
          }}
          value={extraInfos.startDate}
          disabled={isLoadingCurrentFacility}
          isRequired
          classes={{}}
          type="date"
        />
        <TextInput
          labelKey="Start Time"
          forText="startTime"
          name="startTime"
          onChange={(value) => {
            setExtraInfos({ ...extraInfos, startTime: value });
          }}
          value={extraInfos.startTime}
          disabled={isLoadingCurrentFacility}
          isRequired
          classes={{}}
          type="time"
        />
      </div>
      <div className="flex justify-between">
        <TextInput
          labelKey="End Date"
          forText="endDate"
          name="endDate"
          type="date"
          onChange={(value) => {
            setExtraInfos({ ...extraInfos, endDate: value });
          }}
          value={extraInfos.endDate}
          disabled={isLoadingCurrentFacility}
          isRequired
          classes={{}}
        />
        <TextInput
          labelKey="End Time"
          forText="endTime"
          name="endTime"
          type="time"
          onChange={(value) => {
            setExtraInfos({ ...extraInfos, endTime: value });
          }}
          value={extraInfos.endTime}
          disabled={isLoadingCurrentFacility}
          isRequired
          classes={{}}
        />
      </div>

      <TextAreaInput
        labelKey="Description "
        forText="description"
        name="description"
        onChange={(value) => {
          setFields({ ...fields, description: value });
        }}
        value={fields.description}
        disabled={isLoadingCurrentFacility}
        isRequired
        classes={{}}
      />

      <TextAreaInput
        labelKey="Service Instructions  "
        forText="instructions"
        name="instructions"
        onChange={(value) => {
          setFields({ ...fields, instructions: value });
        }}
        value={fields.instructions}
        disabled={isLoadingCurrentFacility}
        isRequired
        classes={{}}
      />

      <div className="mt-4 w-full">
        <div className="flex gap-2 w-full items-center">
          <div className="">{requireInfos ? <Vector2 /> : <Vector1 />}</div>
          <div className="w-full">
            <FormControlLabel
              control={
                <Switch
                  onChange={() => {
                    setrequireInfos(!requireInfos);
                  }}
                  value={requireInfos}
                />
              }
              label="Required information for blood Dobation"
              labelPlacement="start"
              className="flex w-full flex-row justify-between"
              sx={{ display: 'flex', marginLeft: '0', color: '#1DA1F2' }}
            />
          </div>
        </div>
      </div>
      {requireInfos && (
        <>
          <div className="mt-4 w-full">
            <FormControlLabel
              control={
                <Switch
                  onChange={() => {
                    setExtraInfos({
                      ...extraInfos,
                      ageAbove17: !extraInfos.ageAbove17,
                    });
                  }}
                  value={extraInfos.ageAbove17}
                />
              }
              label="Is your age above 17 and below 50 ?"
              labelPlacement="start"
              className="text-dark-3 flex flex-row justify-between"
              sx={{ display: 'flex', marginLeft: '0' }}
            />
          </div>

          <div className="mt-4 w-full">
            <FormControlLabel
              control={
                <Switch
                  onChange={() => {
                    setExtraInfos({
                      ...extraInfos,
                      weightAbove50: !extraInfos.weightAbove50,
                    });
                  }}
                  value={extraInfos.weightAbove50}
                />
              }
              label="Is your weight above 50 Kg?"
              labelPlacement="start"
              className="text-dark-3 flex flex-row justify-between"
              sx={{ display: 'flex', marginLeft: '0' }}
            />
          </div>
          <div className="mt-4 w-full">
            <FormControlLabel
              control={
                <Switch
                  onChange={() => {
                    setExtraInfos({
                      ...extraInfos,
                      heightAbove5: !extraInfos.heightAbove5,
                    });
                  }}
                  value={extraInfos.heightAbove5}
                />
              }
              label="Is your height above 5 ft?"
              labelPlacement="start"
              className="text-dark-3 flex flex-row justify-between"
              sx={{ display: 'flex', marginLeft: '0' }}
            />
          </div>
          <div className="mt-4 w-full">
            <FormControlLabel
              control={
                <Switch
                  onChange={() => {
                    setExtraInfos({
                      ...extraInfos,
                      bloodGroup: !extraInfos.bloodGroup,
                    });
                  }}
                  value={extraInfos.bloodGroup}
                />
              }
              label="Blood group"
              labelPlacement="start"
              className="text-dark-3 flex flex-row justify-between"
              sx={{ display: 'flex', marginLeft: '0' }}
            />
          </div>
          <div className="mt-4 w-full">
            <FormControlLabel
              control={
                <Switch
                  onChange={() => {
                    setExtraInfos({
                      ...extraInfos,
                      donatedBefore: !extraInfos.donatedBefore,
                    });
                  }}
                  value={extraInfos.donatedBefore}
                />
              }
              label="Have you donated before?"
              labelPlacement="start"
              className="text-dark-3 flex flex-row justify-between"
              sx={{ display: 'flex', marginLeft: '0' }}
            />
          </div>
          <div className="mt-4 w-full">
            <FormControlLabel
              control={
                <Switch
                  onChange={() => {
                    setExtraInfos({
                      ...extraInfos,
                      regularMedecine: !extraInfos.regularMedecine,
                    });
                  }}
                  value={extraInfos.regularMedecine}
                />
              }
              label="Do you take any kind of regular medecine?"
              labelPlacement="start"
              className="text-dark-3 flex flex-row justify-between"
              sx={{ display: 'flex', marginLeft: '0' }}
            />
          </div>
          <div className="mt-4 w-full">
            <FormControlLabel
              control={
                <Switch
                  onChange={() => {
                    setExtraInfos({
                      ...extraInfos,
                      smoke: !extraInfos.smoke,
                    });
                  }}
                  value={extraInfos.smoke}
                />
              }
              label="Do you smoke?"
              labelPlacement="start"
              className="text-dark-3 flex flex-row justify-between"
              sx={{ display: 'flex', marginLeft: '0' }}
            />
          </div>
        </>
      )}

      <div className="flex flex-row justify-between">
        <span>&nbsp;</span>
        <Button
          disabled={isLoadingCurrentFacility}
          type="button"
          className="button flex gap-2 mt-10"
          onClick={() => {
            _createService(
              {
                ...fields,
                requiredAdditionalInfo: JSON.stringify(extraInfos),
              },
              currentFacility?.id as string,
              selectedService?.id as string,
            ).then(({ payload }) => {
              if (typeof payload !== 'string') {
                _setCurrentModalType({ type: null, minWidth: 'sm' });
              }
            });
          }}
          isLoading={isLoadingCurrentFacility}
        >
          {!selectedService ? 'Add Service' : 'Update Service'}
        </Button>
      </div>
    </div>
  );
};

interface BloodDonationProps {
  currentFacility: FacilityI | null;
  isLoadingCurrentFacility: boolean;
  selectedService?: FacilityServiceI | null;
  _createService: (
    formData: CreateFacilityServiceDTO,
    facilityId: string,
    serviceId?: string,
  ) => Promise<{
    type: string;
    payload: CreateFacilityServiceDTO | string;
  }>;
  _setCurrentModalType: (options: ModalsState['currentModal']) => {
    type: string;
    payload: ModalsState['currentModal'];
  };
}

const mapStateToProps = ({
  registrationState: { currentFacility, isLoadingCurrentFacility },
}: StoreI) => ({
  currentFacility,
  isLoadingCurrentFacility,
});

export default connect(mapStateToProps, {
  _createService: createService,
  _setCurrentModalType: setCurrentModalType,
})(BloodDonation);

// export default BloodDonation;
