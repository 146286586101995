import React, { useEffect, useState } from 'react';
import {
  Button,
  FacilityOfferedAtOptions,
  FacilityServiceType,
  TextInput,
} from '@mola/client-components';
import { CreateFacilityServiceDTO } from '../../../@types/redux/appRessourceStates/services';
import { ModalsState } from '../../../@types/redux/appRessourceStates/modals';
import { createService } from '../../../store/actions/creators/services';
import { setCurrentModalType } from '../../../store/actions/creators/modals';
import { connect } from 'react-redux';

const Emergency: React.FC<EmergencyProps> = ({
  currentFacility,
  _createService,
  isLoadingCurrentFacility,
  _setCurrentModalType,
  selectedService,
}) => {
  const [fields, setFields] = useState({
    type: FacilityServiceType.EMERGENCY,
    offeredAt: FacilityOfferedAtOptions.FACILITY,
    description: 'N/A',
    totalPrice: 0,
    priceDescription: 'N/A',
    name: FacilityServiceType.EMERGENCY,
    instructions: 'N/A',
    isTravelRequired: false,
    emergencyPhoneNumber: '',
    emergencyEmail: '',
    collectionAtPatientResidence: false,
    requiredAdditionalInfo: 'N/A',
  });

  useEffect(() => {
    if (selectedService) {
      setFields((stateFields) => ({
        ...stateFields,
        ...selectedService,
        type: FacilityServiceType.EMERGENCY,
        offeredAt: FacilityOfferedAtOptions.FACILITY,
        description: selectedService.description,
        totalPrice: selectedService.totalPrice,
        priceDescription: selectedService.priceDescription,
        name: FacilityServiceType.EMERGENCY,
        instructions: selectedService.instructions,
        isTravelRequired: selectedService.isTravelRequired,
        emergencyPhoneNumber: selectedService.emergencyPhoneNumber,
        emergencyEmail: selectedService.emergencyEmail,
      }));
    }
  }, [selectedService]);

  return (
    <div>
      <TextInput
        labelKey="Emergency Phone Number"
        forText="emergencyPhoneNumber"
        name="emergencyPhoneNumber"
        isRequired
        classes={{}}
        onChange={(value) => {
          setFields({ ...fields, emergencyPhoneNumber: value });
        }}
        value={fields.emergencyPhoneNumber}
        disabled={isLoadingCurrentFacility}
      />
      <TextInput
        labelKey="Emergency email"
        forText="emergencyEmail"
        name="emergencyEmail"
        isRequired
        classes={{}}
        onChange={(value) => {
          setFields({ ...fields, emergencyEmail: value });
        }}
        value={fields.emergencyEmail}
        disabled={isLoadingCurrentFacility}
      />

      <div className="flex flex-row justify-between">
        <span>&nbsp;</span>
        <Button
          disabled={isLoadingCurrentFacility}
          type="button"
          className="button flex gap-2 mt-10"
          onClick={() => {
            _createService(fields, currentFacility?.id as string, selectedService?.id).then(
              ({ payload }) => {
                if (typeof payload !== 'string') {
                  _setCurrentModalType({ type: null, minWidth: 'sm' });
                }
              },
            );
          }}
          isLoading={isLoadingCurrentFacility}
        >
          {!selectedService ? 'Add Service' : 'Update Service'}
        </Button>
      </div>
    </div>
  );
};

interface EmergencyProps {
  currentFacility: FacilityI | null;
  _createService: (
    formData: CreateFacilityServiceDTO,
    facilityId: string,
    serviceId?: string,
  ) => Promise<{
    type: string;
    payload: any;
  }>;
  isLoadingCurrentFacility: boolean;
  _setCurrentModalType: (options: ModalsState['currentModal']) => {
    type: string;
    payload: ModalsState['currentModal'];
  };
  selectedService?: FacilityServiceI | null;
}

const mapStateToProps = ({
  registrationState: { currentFacility, isLoadingCurrentFacility },
}: StoreI) => ({
  currentFacility,
  isLoadingCurrentFacility,
});

export default connect(mapStateToProps, {
  _createService: createService,
  _setCurrentModalType: setCurrentModalType,
})(Emergency);
