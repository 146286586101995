import server from '../../services/axios.service';

export const saveImage = async (image: Blob) => {
  const fileData = new FormData();
  fileData.append('file', image as Blob);
  fileData.append('directory', 'facility-portal');

  const {
    data: {
      data: { uploadUrl },
    },
  } = await server.post<ServerResponseT<{ uploadUrl: string }>>('/common/files', fileData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return uploadUrl;
};
