import { Reducer } from 'redux';
import initialState from '../initialState';
import { ModalsActions, SetCurrentModalActionI } from '../../@types/redux/actionCreators/modals';
import { ModalsActionTypes } from '../actions/types/modals';
import { ModalsState } from '../../@types/redux/appRessourceStates/modals';

export const modalsReducer: Reducer<ModalsState, ModalsActions> = (
  state = initialState.modalsState,
  action,
) => {
  switch (action.type) {
    case ModalsActionTypes.SET_CURRENT_MODAL:
      return {
        ...state,
        currentModal: (action as SetCurrentModalActionI).payload,
      };

    default:
      return state;
  }
};
