import React from 'react';
import { connect } from 'react-redux';
// import { UsersState } from '../../@types/redux/appRessourcesStates/services';
// import { UsersState } from '../../@types/redux/appRessourcesStates/users';
import { TextAreaInput, Button } from '@mola/client-components';
import { ServicesState } from '../../../@types/redux/appRessourceStates/services';
import { deleteService } from '../../../store/actions/creators/services';
import { setCurrentModalType } from '../../../store/actions/creators/modals';
import { ModalsState } from '../../../@types/redux/appRessourceStates/modals';

const DeleteServiceModal: React.FC<DeleteServiceModalProps> = ({
  selectedService,
  _deleteService,
  _deleteServiceState,
  _setDeleteServiceModalType,
}) => {
  return (
    <div className="p-8">
      <p className="text-2xl text-dark font-bold">Delete Service</p>

      <p className="mt-8">
        Are you sure you want to delete <span className="font-bold">{selectedService?.name}</span>
      </p>
      <div className="mt-8">
        <TextAreaInput
          labelKey="Reason for deletion"
          forText="reason"
          name="reason"
          isRequired
          classes={{}}
          placeHolder="Type something...."
        />
      </div>

      <div className="mt-4 text-right">
        <Button
          className={`button bg-danger`}
          type="button"
          isLoading={_deleteServiceState.loading}
          onClick={() =>
            _deleteService((selectedService as FacilityServiceI).id).then(() => {
              _setDeleteServiceModalType({ type: null, minWidth: 'md' });
            })
          }
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

interface DeleteServiceModalProps {
  selectedService: ServicesState['selectedService'];
  _deleteServiceState: ServicesState['deleteService'];
  _deleteService: (facilityId: string) => Promise<{
    type: string;
  }>;
  _setDeleteServiceModalType: (options: ModalsState['currentModal']) => {
    type: string;
    payload: ModalsState['currentModal'];
  };
}

const mapStateToProps = ({
  servicesState: { selectedService, deleteService: _deleteServiceState },
}: StoreI) => ({
  selectedService,
  _deleteServiceState,
});
export default connect(mapStateToProps, {
  _deleteService: deleteService,
  _setDeleteServiceModalType: setCurrentModalType,
})(DeleteServiceModal);
