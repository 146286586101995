import React from 'react';
import { ReactComponent as Step1Icon } from '../../../assets/svg/4StepsSterpper-step1.svg';
import { Select } from '@mui/material';
import { SearchInput, TextInput } from '@mola/client-components';
import { ReactComponent as SearchIcon } from '../../../assets/svg/search-icon.svg';

const Step1: React.FC<StepProps> = ({ goNext }) => {
  return (
    <div className="p-6">
      <h3 className="flex flex-row justify-between gap-20 text-primary text-xl pb-2">
        <span className="">Patient details</span> <Step1Icon className="mt-2 " />
      </h3>
      <SearchInput
        labelKey=""
        forText="search"
        name="lastName"
        isRequired={false}
        classes={{}}
        placeHolder="Search Mola ID"
        onSearchClicked={() => {}}
        getSearchIcon={() => <SearchIcon />}
      />
      <TextInput
        labelKey="First Name"
        forText="firstName"
        name="firstName"
        isRequired
        classes={{}}
      />
      <TextInput labelKey="Last Name" forText="lastName" name="lastName" isRequired classes={{}} />
      <TextInput labelKey="Email" forText="email" name="email" isRequired={false} classes={{}} />
      <div className="mt-4 w-full">
        <label
          htmlFor="facility-category"
          className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
        >
          Gender <span className="text-gray-400">*</span>
        </label>
        <Select name="gender" id="user-gender" className="rounded-md h-10 mt-2 w-full px-1">
          <option value="male" key={0} className="hover:bg-grey-4 cursor-pointer">
            Male
          </option>
          <option value="female" key={1} className="hover:bg-grey-4 cursor-pointer">
            Female
          </option>
        </Select>
      </div>
      <TextInput
        labelKey="Phone Number"
        forText="phoneNumber"
        name="email"
        isRequired={false}
        classes={{}}
      />
      <TextInput
        labelKey="Address"
        forText="email"
        name="address"
        isRequired={false}
        classes={{}}
      />
      <div className="flex flex-row justify-between">
        <span>&nbsp;</span>
        <button
          type="button"
          className="button flex gap-2 mt-10"
          onClick={() => {
            goNext();
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

interface StepProps {
  goNext: () => void;
}

export default Step1;
