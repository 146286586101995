import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Accordion, AccordionDetails } from '@mui/material';
import { CheckboxInput } from '@mola/client-components';

const VaccineDisplay: React.FC<VaccineDisplayProps> = ({
  title,
  description,
  price,
  feesDetails,
}) => {
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  return (
    <div className="border border-dark-4 rounded-md p-4 mt-10">
      <div className="flex flex-row justify-between font-bold">
        {title} <CheckboxInput labelText="" />
      </div>
      <div className="py-2 text-xl">
        <FontAwesomeIcon
          icon={faMoneyBillAlt}
          className="text-green-600 text-xl mr-2 mt-1 font-light"
        />
        {price} Ksh
      </div>
      <Accordion
        sx={{
          boxShadow: 'unset',
          border: 'unset',
        }}
        onChange={(e, expanded) => {
          setAccordionExpanded(expanded);
        }}
      >
        <AccordionSummary
          expandIcon={<FontAwesomeIcon icon={faChevronDown} className="text-dark" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <span className="text-secondary text-xs">
            {accordionExpanded ? 'Show less' : 'Show more'}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="text-sm">{description}</div>
          <br />
          {Object.keys(feesDetails).map((fee) => (
            <div key={Math.random()} className="text-sm text-dark-3 flex flex-row justify-between">
              <span>{fee}:</span> <span>Ksh {feesDetails[fee]}</span>
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

interface VaccineDisplayProps {
  title: string;
  description: string;
  price: number;
  feesDetails: { [key: string]: number };
}

export default VaccineDisplay;
