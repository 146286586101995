import React from 'react';
import { Popover, Select } from '@mui/material';
import { PaymentChannels, ServiceRequestStatus } from '@mola/client-components';
import AssignPractitionerPopover from './AssignPractitionerPopover';
import CompleteOrderPopOver from './CompleteOrderPopOver';
import ConfirmCashPopover from './ConfirmationPopover';
import { RequestDetailsModalActions } from '../../../@types/requests';

const RequestDetailsModalActionSelector: React.FC<RequestDetailsModalActionSelectorProps> = ({
  request,
}) => {
  let requestAction: string[];
  const [showAssignPractitionerAnchorEl, setShowAssignPractitionerAnchorEl] =
    React.useState<HTMLSpanElement | null>(null);

  const [selectedAction, setSelectedAction] = React.useState('Choose Action...');

  const ref = React.useRef<HTMLSpanElement>(null);

  const showAssignPractitioner = () => {
    setShowAssignPractitionerAnchorEl(ref.current);
  };
  const hideAssignPractitioner = () => {
    setShowAssignPractitionerAnchorEl(null);
  };

  const isAssignPractitionerShown = Boolean(showAssignPractitionerAnchorEl);
  const assignPractitionerPopoverId = isAssignPractitionerShown
    ? 'simple-popover-menuPopoverId'
    : undefined;

  const [showCompleteOrderAnchorEl, setShowCompleteOrderAnchorEl] =
    React.useState<HTMLSpanElement | null>(null);

  const showCompleteOrder = () => {
    setShowCompleteOrderAnchorEl(ref.current);
  };
  const hideCompleteOrder = () => {
    setShowCompleteOrderAnchorEl(null);
  };

  const isCompleteOrderShown = Boolean(showCompleteOrderAnchorEl);
  const completeOrderPopoverId = isCompleteOrderShown ? 'simple-popover-menuPopoverId' : undefined;

  const [showConfirmationPopoverAnchorEl, setShowConfirmationPopoverAnchorEl] =
    React.useState<HTMLSpanElement | null>(null);

  const showConfirmationPopover = () => {
    setShowConfirmationPopoverAnchorEl(ref.current);
  };
  const hideConfirmCash = () => {
    setShowConfirmationPopoverAnchorEl(null);
  };

  const isConfirmCashShown = Boolean(showConfirmationPopoverAnchorEl);
  const confirmCashPopoverId = isConfirmCashShown ? 'simple-popover-menuPopoverId' : undefined;

  switch (request.status) {
    case ServiceRequestStatus.NEW:
      requestAction = [RequestDetailsModalActions.QUEUE, RequestDetailsModalActions.REJECT_ORDER];

      if (request.payment?.channel === PaymentChannels.CASH) {
        requestAction = [
          RequestDetailsModalActions.CONFIRM_CASH_PAYMENT,
          RequestDetailsModalActions.REJECT_ORDER,
        ];
      }

      break;

    case ServiceRequestStatus.QUEUED:
      requestAction = [
        RequestDetailsModalActions.ASSIGN_PRACTITIONER,
        RequestDetailsModalActions.REJECT_ORDER,
      ];
      break;

    case ServiceRequestStatus.ON_GOING:
      requestAction = [RequestDetailsModalActions.COMPLETE_ORDER];
      break;

    default:
      requestAction = [''];
  }

  return (
    <>
      <Select
        name="gender"
        id="user-gender"
        className="rounded-md h-10 mt-10 w-max px-1 bg-secondary"
        native
        value={selectedAction}
        sx={{
          color: 'white',
          width: '90%',
        }}
        onChange={(e) => {
          setSelectedAction(e.target.value);

          switch (e.target.value) {
            case RequestDetailsModalActions.ASSIGN_PRACTITIONER:
              showAssignPractitioner();
              break;

            case RequestDetailsModalActions.COMPLETE_ORDER:
              showCompleteOrder();
              break;

            case RequestDetailsModalActions.CONFIRM_CASH_PAYMENT:
              showConfirmationPopover();
              break;

            case RequestDetailsModalActions.REJECT_ORDER:
              showConfirmationPopover();
              break;

            case RequestDetailsModalActions.QUEUE:
              showConfirmationPopover();
              break;

            default:
              break;
          }
        }}
      >
        <option value="pending" className="hover:bg-grey-4 cursor-pointer">
          Choose Action...
        </option>
        {requestAction.map((el) => (
          <option value={el} key={Math.random()} className="hover:bg-grey-4 cursor-pointer">
            {el}
          </option>
        ))}
      </Select>

      <span ref={ref}>&nbsp;</span>

      <Popover
        id={assignPractitionerPopoverId}
        open={isAssignPractitionerShown}
        anchorEl={showAssignPractitionerAnchorEl}
        onClose={hideAssignPractitioner}
        anchorOrigin={{
          vertical: 40,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <AssignPractitionerPopover hideAssignPractitioner={hideAssignPractitioner} />
      </Popover>

      <Popover
        id={completeOrderPopoverId}
        open={isCompleteOrderShown}
        anchorEl={showCompleteOrderAnchorEl}
        onClose={hideCompleteOrder}
        anchorOrigin={{
          vertical: 40,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <CompleteOrderPopOver request={request} />
      </Popover>

      <Popover
        id={confirmCashPopoverId}
        open={isConfirmCashShown}
        anchorEl={showConfirmationPopoverAnchorEl}
        onClose={hideConfirmCash}
        anchorOrigin={{
          vertical: 40,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ConfirmCashPopover actionMessage={selectedAction as RequestDetailsModalActions} />
      </Popover>
    </>
  );
};

interface RequestDetailsModalActionSelectorProps {
  request: Merge<RequestI, { payment?: PaymentI }>;
}

export default RequestDetailsModalActionSelector;
