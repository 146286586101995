import React from 'react';
import { FacilityServiceType } from '@mola/client-components';

const ServiceTypeDisplay: React.FC<RequestProps> = ({ requestedServiceType }) => {
  const bgColor = (serviceType: FacilityServiceType) => {
    switch (serviceType) {
      case FacilityServiceType.CONSULTATION:
        return 'bg-primary-light text-gray-100';
      case FacilityServiceType.VACCINATION:
        return 'bg-yellow-500 text-yellow-100';
      case FacilityServiceType.BLOOD_REQUEST:
        return 'bg-pink-500 text-white';
      case FacilityServiceType.EMERGENCY:
        return 'bg-red-500 text-red-100';
      case FacilityServiceType.LAB:
        return 'bg-purple-500 text-purple-100';
      default:
        return 'bg-dark';
    }
  };

  return (
    <div className={`px-2 py-1 w-max rounded ${bgColor(requestedServiceType)}`}>
      {requestedServiceType}
    </div>
  );
};

interface RequestProps {
  requestedServiceType: FacilityServiceType;
}

export default ServiceTypeDisplay;
