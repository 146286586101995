import React, { useState } from 'react';
import { ReactComponent as Step1Icon } from '../../../assets/svg/4StepsSterpper-step2.svg';
import { Select } from '@mui/material';
import VaccinationDetails from './VaccinationDetails';
import { TextAreaInput, TextInput, FacilityServiceType } from '@mola/client-components';
import ConsultationDetails from './ConsultationDetails';
import LabRequestDetails from './LabRequestDetails';
import PharmacyDetails from './PharmacyDetails';
import BloodDonationDetails from './BloodDonationDetails';

const practitioners = ['John Doe', 'Jane Doe'];

const Step2: React.FC<StepProps> = ({ goNext, goBack }) => {
  const [fields, setFields] = useState({
    serviceType: FacilityServiceType.CONSULTATION,
    isVaccinationOut: false,
  });

  return (
    <div className="p-6">
      <h3 className="flex flex-row justify-between gap-20 text-primary text-xl pb-2">
        <span className="">Request details</span> <Step1Icon className="mt-2 " />
      </h3>
      <div className="mt-4 w-full">
        <label
          htmlFor="facility-category"
          className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
        >
          Service <span className="text-gray-400">*</span>
        </label>
        <Select
          name="gender"
          id="user-gender"
          className="rounded-md h-10 mt-2 w-full px-1"
          value={fields.serviceType}
          onChange={({ target: { value } }) => {
            setFields({
              ...fields,
              serviceType: value as FacilityServiceType,
            });
          }}
          native
        >
          {Object.values(FacilityServiceType).map((value) => (
            <option
              value={value}
              key={Math.random()}
              className="hover:bg-grey-4 cursor-pointer px-2 py-1"
            >
              {value}
            </option>
          ))}
        </Select>
      </div>
      <div className="flex flex-row justify-between gap-5">
        <TextInput
          labelKey="Start Date"
          forText="lastName"
          name="lastName"
          isRequired={false}
          classes={{}}
          placeHolder="Search Practitioner"
          type="date"
        />
        <TextInput
          labelKey="Start Time"
          forText="lastName"
          name="lastName"
          isRequired={false}
          classes={{}}
          placeHolder="Search Practitioner"
          type="time"
        />
      </div>
      <TextAreaInput
        forText="user-comment"
        labelKey="Description"
        placeHolder="Add description here"
        isRequired={false}
        classes={{
          container: 'w-full sm:p-0 sm:m-0 mt-2',
          input: 'h-32',
        }}
      />
      {fields.serviceType === FacilityServiceType.VACCINATION && (
        <VaccinationDetails fields={fields} setFields={setFields} practitioners={practitioners} />
      )}
      {fields.serviceType === FacilityServiceType.CONSULTATION && <ConsultationDetails />}

      {fields.serviceType === FacilityServiceType.LAB && <LabRequestDetails />}

      {fields.serviceType === FacilityServiceType.PHARMACY && <PharmacyDetails />}

      {fields.serviceType === FacilityServiceType.BLOOD_REQUEST && <BloodDonationDetails />}

      <div className="flex flex-row justify-between">
        <button
          type="button"
          className="button flex gap-2 mt-10"
          onClick={() => {
            goBack();
          }}
        >
          Back
        </button>
        <button
          type="button"
          className="button flex gap-2 mt-10"
          onClick={() => {
            goNext(fields.serviceType);
          }}
        >
          {fields.serviceType === FacilityServiceType.CONSULTATION ? 'Create Request' : 'Next'}
        </button>
      </div>
    </div>
  );
};

interface StepProps {
  goNext: (service: string) => void;
  goBack: () => void;
}

export default Step2;
