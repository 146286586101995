import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import server from '../../../services/axios.service';
import {
  QueueRequestActionI,
  RejectRequestActionI,
  SetAllRequestsActionI,
  SetCurrentlyDisplayedRequestActionI,
} from '../../../@types/redux/actionCreators/requests';
import { RequestsActionTypes } from '../types/requests';
import { UiActionTypes } from '../types/ui';
import { ServiceRequestStatus } from '@mola/client-components';

export const getAllRequests: ActionCreator<
  ThunkAction<
    Promise<{
      type: string;
      payload: PaginatedItems<RequestI> | string;
    }>,
    {
      type: string;
      payload: PaginatedItems<RequestI> | string;
    },
    string,
    SetAllRequestsActionI
  >
> = (id: string) => async (dispatch: Dispatch) => {
  const data = {};
  dispatch({
    type: RequestsActionTypes.SET_IS_LOADING_ALL_REQUESTS,
    payload: true,
  });

  try {
    const res = await server.get<ServerResponseT<PaginatedItems<RequestI>>>(
      `/facilities/${id}/service-requests/`,
      data,
    );

    return dispatch({
      type: RequestsActionTypes.SET_ALL_REQUESTS,
      payload: res.data.data as PaginatedItems<RequestI>,
    });
  } catch (error) {
    return dispatch({
      type: UiActionTypes.SET_CURRENT_ERROR,
      payload: 'Something went wrong',
    });
  }
};

export const confirmCashPayment: ActionCreator<
  ThunkAction<
    Promise<{
      type: string;
      payload: { id: string; status: ServiceRequestStatus } | string;
    }>,
    {
      type: string;
      payload: { id: string; status: ServiceRequestStatus } | null;
    },
    string,
    SetAllRequestsActionI
  >
> = (id: string) => async (dispatch: Dispatch) => {
  try {
    await server.put<ServerResponseT<RequestI>>(`/service-requests/${id}/payment-approve`, {
      id: 'sdksdlskdl',
    });

    return dispatch({
      type: RequestsActionTypes.CONFIRM_CASH_PAYMENT,
      payload: { id, status: ServiceRequestStatus.QUEUED },
    });
  } catch (error) {
    return dispatch({
      type: UiActionTypes.SET_CURRENT_ERROR,
      payload: 'Something went wrong',
    });
  }
};

export const setRequest: ActionCreator<
  ThunkAction<
    Promise<{
      type: string;
      payload: RequestI | null;
    }>,
    {
      type: string;
      payload: RequestI | null;
    },
    RequestI,
    SetCurrentlyDisplayedRequestActionI
  >
> = (request: RequestI) => async (dispatch: Dispatch) => {
  return dispatch({
    type: RequestsActionTypes.SET_CURRENTLY_DISPLAYED_REQUEST,
    payload: request,
  });
};

export const rejectRequest: ActionCreator<
  ThunkAction<
    Promise<{
      type: string;
      payload: RequestI | string;
    }>,
    {
      type: string;
      payload: RequestI | string;
    },
    RequestI,
    RejectRequestActionI
  >
> = (request: RequestI) => async (dispatch: Dispatch) => {
  try {
    await server.put(`/service-requests/${request.id}/reject`, {
      request,
    });

    return dispatch({
      type: RequestsActionTypes.REJECT_REQUEST,
      payload: request,
    });
  } catch (error: any) {
    console.log(error.response);
    return dispatch({
      type: UiActionTypes.SET_CURRENT_ERROR,
      payload: error.response ? error.response.data.message : 'Something went wrong',
    });
  }
};

export const queueRequest: ActionCreator<
  ThunkAction<
    Promise<{
      type: string;
      payload: RequestI | string;
    }>,
    {
      type: string;
      payload: RequestI | string;
    },
    RequestI,
    QueueRequestActionI
  >
> = (request: RequestI) => async (dispatch: Dispatch) => {
  try {
    await server.put(`/service-requests/${request.id}/queue`, {
      request,
    });

    return dispatch({
      type: RequestsActionTypes.QUEUE_REQUEST,
      payload: request,
    });
  } catch (error: any) {
    console.log(error.response);
    return dispatch({
      type: UiActionTypes.SET_CURRENT_ERROR,
      payload: error.response ? error.response.data.message : 'Something went wrong',
    });
  }
};

export const completeRequestOrder: ActionCreator<
  ThunkAction<
    Promise<{
      type: string;
      payload: RequestI | string;
    }>,
    {
      type: string;
      payload: RequestI | string;
    },
    RequestI,
    QueueRequestActionI
  >
> =
  (
    request: RequestI,
    additionalData: {
      TTNumber?: string;
      testResultsReportLink?: string;
      vaccinationCertificateLink?: string;
      donatedBloodQuantity?: string;
    },
  ) =>
  async (dispatch: Dispatch) => {
    try {
      await server.put(`/service-requests/${request.id}/complete-order`, additionalData);

      return dispatch({
        type: RequestsActionTypes.COMPLETE_REQUEST_ORDER,
        payload: request,
      });
    } catch (error: any) {
      console.log(error.response);
      return dispatch({
        type: UiActionTypes.SET_CURRENT_ERROR,
        payload: error.response ? error.response.data.message : 'Something went wrong',
      });
    }
  };

export const assignPractitioner =
  (
    serviceRequest: Merge<{ practitioner?: PractitionerI }, RequestI> | null,
    practitionerData: {
      practitioner: PractitionerI;
      roomNumber: string;
      startDate: Date;
    },
  ) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: RequestsActionTypes.SET_IS_LOADING_ASSIGN_PRACTITIONER,
      payload: true,
    });

    try {
      await server.put(`/service-requests/${serviceRequest?.id}/practitioner`, {
        practitionerId: practitionerData.practitioner.id,
        roomNumber: practitionerData.roomNumber,
        startDate: practitionerData.startDate,
      });
      return dispatch({
        type: RequestsActionTypes.ASSIGN_PRACTITIONER,
        payload: { serviceRequest, practitionerData },
      });
    } catch (error: any) {
      return dispatch({
        type: RequestsActionTypes.SET_ERROR_ASSIGN_PRACTITIONER,
        payload: error.response
          ? error.response.data.message
          : 'Something went wrong while trying to assign a practitioner',
      });
    }
  };
