import React from 'react';
import { ReactComponent as Step1Icon } from '../../../assets/svg/4StepsSterpper-step4.svg';
import { Select } from '@mui/material';
import { TextInput } from '@mola/client-components';

const EmergencyFinalStep: React.FC<StepProps> = ({ goNext, goBack }) => {
  return (
    <div className="p-6">
      <h3 className="flex flex-row justify-between gap-20 text-primary text-xl pb-2">
        <span className="">Request details</span> <Step1Icon className="mt-2 " />
      </h3>
      <TextInput labelKey="Location" forText="location" name="location" isRequired classes={{}} />
      <div className="mt-4 w-full">
        <label
          htmlFor="facility-category"
          className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
        >
          Ambulance driver <span className="text-gray-400">*</span>
        </label>
        <Select name="gender" id="user-gender" className="rounded-md h-10 mt-2 w-full px-1" native>
          <option value="male" key={0} className="hover:bg-grey-4 cursor-pointer">
            Select
          </option>
        </Select>
      </div>
      <div className="flex flex-row justify-between">
        <button
          type="button"
          className="button flex gap-2 mt-10"
          onClick={() => {
            goBack();
          }}
        >
          Back
        </button>
        <button
          type="button"
          className="button flex gap-2 mt-10"
          onClick={() => {
            goNext();
          }}
        >
          Crete request
        </button>
      </div>
    </div>
  );
};

interface StepProps {
  goNext: () => void;
  goBack: () => void;
}

export default EmergencyFinalStep;
