import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  SetAllRequestsActionI,
  SetIsLoadingAllRequestsActionI,
  RequestsActions,
  SetCurrentlyDisplayedRequestActionI,
  ConfirmCashPaymentActionI,
  RejectRequestActionI,
  QueueRequestActionI,
  CompleteRequestOrderActionI,
  SetIsLoadingAssignPractitionerActionI,
  AssignPractitionerToRequestActionI,
  SetErrorAssignPractitionerActionI,
} from '../../@types/redux/actionCreators/requests';
import { RequestsActionTypes } from '../actions/types/requests';
import { RequestsState } from '../../@types/redux/appRessourceStates/requests';
import { toast } from 'react-toastify';
import { ServiceRequestStatus } from '@mola/client-components';

export const requestsReducer: Reducer<RequestsState, RequestsActions> = (
  state = initialState.requestsState,
  action,
): RequestsState => {
  switch (action.type) {
    case RequestsActionTypes.SET_ALL_REQUESTS:
      return {
        ...state,
        requests: (action as SetAllRequestsActionI).payload,
        isLoadingRequests: false,
      };

    case RequestsActionTypes.SET_CURRENTLY_DISPLAYED_REQUEST:
      return {
        ...state,
        request: (action as SetCurrentlyDisplayedRequestActionI).payload,
      };

    case RequestsActionTypes.CONFIRM_CASH_PAYMENT: {
      toast.success('Cash Payment Approved Successfully', { autoClose: 3000 });
      return {
        ...state,
        requests: {
          ...state.requests,
          rows: state.requests.rows.map((reque) =>
            reque.id === (action as ConfirmCashPaymentActionI).payload.id
              ? { ...reque, status: (action as ConfirmCashPaymentActionI).payload.status }
              : reque,
          ),
        },
      };
    }

    case RequestsActionTypes.SET_IS_LOADING_ALL_REQUESTS:
      return {
        ...state,
        isLoadingRequests: (action as SetIsLoadingAllRequestsActionI).payload,
      };

    case RequestsActionTypes.REJECT_REQUEST:
      toast.success('Updates saved Successfully', { autoClose: 3000 });

      return {
        ...state,
        requests: {
          ...state.requests,
          rows: state.requests.rows.map((request) =>
            request.id === (action as RejectRequestActionI).payload.id
              ? { ...request, status: ServiceRequestStatus.REJECTED }
              : request,
          ),
        },
      };

    case RequestsActionTypes.QUEUE_REQUEST:
      toast.success('request successfully put into queue', { autoClose: 3000 });

      return {
        ...state,
        requests: {
          ...state.requests,
          rows: state.requests.rows.map((request) =>
            request.id === (action as QueueRequestActionI).payload.id
              ? { ...request, status: ServiceRequestStatus.QUEUED }
              : request,
          ),
        },
      };

    case RequestsActionTypes.COMPLETE_REQUEST_ORDER:
      toast.success('request successfully completed', { autoClose: 3000 });

      return {
        ...state,
        requests: {
          ...state.requests,
          rows: state.requests.rows.map((request) =>
            request.id === (action as CompleteRequestOrderActionI).payload.id
              ? { ...request, status: ServiceRequestStatus.COMPLETED }
              : request,
          ),
        },
      };

    case RequestsActionTypes.SET_IS_LOADING_ASSIGN_PRACTITIONER:
      return {
        ...state,
        isLoadingAssignPractitioner: (action as SetIsLoadingAssignPractitionerActionI).payload,
      };

    case RequestsActionTypes.SET_ERROR_ASSIGN_PRACTITIONER:
      toast.error((action as SetErrorAssignPractitionerActionI).payload);

      return {
        ...state,
        isLoadingAssignPractitioner: false,
        errorAssignPractitioner: (action as SetErrorAssignPractitionerActionI).payload,
      };

    case RequestsActionTypes.ASSIGN_PRACTITIONER: {
      toast.success('Practitioner assigned successfully', { autoClose: 3000 });
      const payload = (action as AssignPractitionerToRequestActionI).payload;

      return {
        ...state,
        isLoadingAssignPractitioner: false,
        request: {
          ...state.request,
          practitioner: {
            ...payload.practitionerData.practitioner,
          },
          status: ServiceRequestStatus.ON_GOING,
          roomNumber: payload.practitionerData.roomNumber,
          startedAt: payload.practitionerData.startDate,
        } as RequestsState['request'],
        requests: {
          ...state.requests,
          rows: state.requests.rows.map((request) =>
            request.id === payload.serviceRequest?.id
              ? {
                  ...request,
                  practitioner: {
                    ...payload.practitionerData.practitioner,
                  },
                  status: ServiceRequestStatus.ON_GOING,
                  roomNumber: payload.practitionerData.roomNumber,
                  startDate: payload.practitionerData.startDate,
                }
              : request,
          ),
        },
      };
    }
    default:
      return state;
  }
};
