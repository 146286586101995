import React, { useState, useEffect } from 'react';
import { Select } from '@mui/material';
import Consultation from './DetailsModal/Consultation';
import Emergency from './DetailsModal/Emergency';
import Vaccination from './DetailsModal/Vaccine';
import BloodDonation from './DetailsModal/BloodDonation';
import { FacilityServiceType } from '@mola/client-components';
import { connect } from 'react-redux';
import { ServicesState } from '../../@types/redux/appRessourceStates/services';

const serviceCategories = [
  {
    value: FacilityServiceType.CONSULTATION,
    display: 'Consultation',
  },
  {
    value: FacilityServiceType.LAB,
    display: 'Lab Request',
  },
  {
    value: FacilityServiceType.EMERGENCY,
    display: 'Emergency',
  },
  {
    value: FacilityServiceType.VACCINATION,
    display: 'Vaccination',
  },
  {
    value: FacilityServiceType.BLOOD_REQUEST,
    display: 'Blood Donation',
  },
];

const CreateService: React.FC<CreateServiceModalProps> = ({ selectedService }) => {
  const [category, setcategory] = useState<FacilityServiceType>(FacilityServiceType.CONSULTATION);

  useEffect(() => {
    if (selectedService) {
      setcategory(selectedService.type);
    }
  }, [selectedService]);

  return (
    <div
      className="px-4 pt-4 pb-0"
      style={{
        width: 500,
      }}
    >
      <h1 className="text-xl text-dark pl-6 font-bold">
        {!selectedService ? 'New Service' : 'Update Service'}
      </h1>
      <div className="p-6">
        <h3 className="text-primary text-xl pb-2">
          <span className="">Service details</span>
        </h3>

        <div className="mt-4 w-full">
          <label
            htmlFor="facility-category"
            className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
          >
            Service Category <span className="text-gray-400">*</span>
          </label>
          <Select
            name="gender"
            id="user-gender"
            className="rounded-md h-10 mt-2 w-full px-1"
            value={category}
            onChange={({ target: { value } }) => {
              setcategory(value as FacilityServiceType);
            }}
            native
          >
            {serviceCategories.map((service) => (
              <option
                value={service.value}
                key={Math.random()}
                className="hover:bg-grey-4 cursor-pointer"
              >
                {service.display}
              </option>
            ))}
          </Select>
        </div>
        {(category === FacilityServiceType.CONSULTATION ||
          category === FacilityServiceType.LAB) && (
          <Consultation selectedService={selectedService} serviceCategory={category} />
        )}
        {category === FacilityServiceType.EMERGENCY && (
          <Emergency selectedService={selectedService} />
        )}
        {category === FacilityServiceType.VACCINATION && (
          <Vaccination selectedService={selectedService} />
        )}
        {category === FacilityServiceType.BLOOD_REQUEST && (
          <BloodDonation selectedService={selectedService} />
        )}
      </div>
    </div>
  );
};

interface CreateServiceModalProps {
  selectedService?: ServicesState['selectedService'];
}

const mapStateToProps = ({ servicesState: { selectedService } }: StoreI) => ({
  selectedService,
});
export default connect(mapStateToProps)(CreateService);
