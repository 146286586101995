import React, { useState } from 'react';
import { FormControlLabel, Select, Switch } from '@mui/material';
import { TextInput } from '@mola/client-components';

const BloodDonationDetails = () => {
  const [isOutOfFacility, setisOutOfFacility] = useState(false);
  return (
    <>
      <FormControlLabel
        control={
          <Switch
            onChange={() => {
              setisOutOfFacility(!isOutOfFacility);
            }}
            value={isOutOfFacility}
          />
        }
        label="Is the donation taking place out of facility ?"
        labelPlacement="start"
        className="text-dark-3 flex flex-row justify-between"
        sx={{ display: 'flex', marginLeft: '0' }}
      />
      {isOutOfFacility && (
        <div className="mt-4 w-full">
          <div className="mt-4 w-full">
            <label
              htmlFor="facility-category"
              className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
            >
              Practitioner
            </label>
            <Select
              name="gender"
              id="user-gender"
              className="rounded-md h-10 mt-2 w-full px-1 active"
              native
            >
              <option value="male" key={0} className="hover:bg-grey-4 cursor-pointer">
                Select
              </option>
            </Select>
          </div>
          <TextInput
            labelKey="Location Adress"
            forText="location"
            name="location"
            isRequired
            classes={{}}
          />
        </div>
      )}
    </>
  );
};

export default BloodDonationDetails;
