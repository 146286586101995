const initialState: StoreI = {
  modalsState: {
    currentModal: { minWidth: 'sm', type: null },
  },
  registrationState: {
    isAuth: false,
    currentUser: null,
    tokens: {
      access: '',
      passwordReset: '',
      refresh: '',
    },
    errorMessage: null,
    isLoading: false,
    message: null,
    tokensStorage: 'sessionStorage',
    currentFacility: null,
    isLoadingCurrentFacility: false,
  },
  uiState: { errorMessage: '' },
  requestsState: {
    requests: {
      rows: [],
    },
    request: null,
    isLoadingRequests: false,
    isLoadingAssignPractitioner: false,
    errorAssignPractitioner: null,
  },
  servicesState: {
    selectedService: null,
    currentServices: {
      rows: [],
    },
    isLoadingCurrentServices: false,
    errorMessage: null,
    currentError: null,
    deleteService: {
      data: null,
      loading: false,
    },
  },
  practitionersState: {
    isLoadingPractitioners: false,
    currentError: '',
    practitioners: {
      rows: [],
    },
  },
};

export default initialState;
