import React, { useState } from 'react';
import RegisterFacilityStepOne from '../../EditFacility/RegisterStepOne';
import RegisterFacilityStepTwo from '../../EditFacility/RegisterStepTwo';
import RegisterFacilityStepThree from '../../EditFacility/RegisterStepThree';
import RegisterFacilityStepFour from '../../EditFacility/RegisterStepFour';
import RegisterFacilityStepFive from '../../EditFacility/RegisterStepFive';
import { connect } from 'react-redux';
import { FacilityCategory, FacilityType } from '@mola/client-components';
import { editFacility } from '../../../store/actions/creators/registration';

export enum FacilitySteps {
  PRIMARY_DETAILS = 1,
  ABOUT_DETAILS = 2,
  ADDRESS_AND_LANGUAGE_DETAILS = 3,
  FACILITY_ADMIN_DETAILS = 4,
  SUCCESS = 5,
}

const RegisterFacility: React.FC<RegisterFacilityProps> = ({ facility, _editFacility }) => {
  const [currentStep, setCurrentStep] = useState<FacilitySteps>(FacilitySteps.PRIMARY_DETAILS);
  const [isLoading, setIsLoading] = useState(false);

  const step1InitialState = {
    name: facility?.name as string,
    type: facility?.type as FacilityType,
  };

  const step2InitialState = {
    category: facility?.category as FacilityCategory,
    description: facility?.description as string,
    website: facility?.website as string,
  };

  const step3InitialState = {
    country: facility?.country as string,
    city: facility?.city as string,
    address: facility?.address as string,
    languages: facility?.supportedLanguages as string,
  };

  const step4InitialState = {
    email: facility?.email as string,
    password: facility?.adminAndReceptionistUsers[0].password as string,
    name: facility?.name as string,
  };

  type FieldsT = Merge<
    Merge<typeof step1InitialState, typeof step2InitialState>,
    Merge<typeof step3InitialState, { admin: typeof step4InitialState }>
  >;

  const [fields, setFields] = useState<FieldsT>({
    ...step1InitialState,
    ...step2InitialState,
    ...step3InitialState,
    admin: { ...step4InitialState },
  });

  const getCurrentStep = () => {
    switch (currentStep) {
      case FacilitySteps.PRIMARY_DETAILS:
        return (
          <RegisterFacilityStepOne
            goNext={(next) => {
              setFields({
                ...fields,
                ...next,
              });
              setCurrentStep(FacilitySteps.ABOUT_DETAILS);
            }}
            initialState={{
              type: fields.type,
              name: fields.name,
            }}
          />
        );
      case FacilitySteps.ABOUT_DETAILS:
        return (
          <RegisterFacilityStepTwo
            goNext={(newState) => {
              setFields({ ...fields, ...newState });
              setCurrentStep(FacilitySteps.ADDRESS_AND_LANGUAGE_DETAILS);
            }}
            goBack={() => {
              setCurrentStep(FacilitySteps.PRIMARY_DETAILS);
            }}
            initialState={{
              description: fields.description,
              website: fields.website,
              category: fields.category,
            }}
          />
        );
      case FacilitySteps.ADDRESS_AND_LANGUAGE_DETAILS:
        return (
          <RegisterFacilityStepThree
            goNext={(nextState) => {
              setCurrentStep(FacilitySteps.FACILITY_ADMIN_DETAILS);
              setFields({
                ...fields,
                ...nextState,
              });
            }}
            goBack={() => {
              setCurrentStep(FacilitySteps.ABOUT_DETAILS);
            }}
            initialState={{
              address: fields.address,
              city: fields.city,
              country: fields.country,
              languages: fields.languages,
            }}
          />
        );
      case FacilitySteps.FACILITY_ADMIN_DETAILS:
        return (
          <RegisterFacilityStepFour
            goNext={(nextState) => {
              setIsLoading(true);
              setFields({ ...fields, admin: { ...fields.admin, ...nextState } });
              _editFacility({ ...facility, ...(fields as any) } as FacilityI).then(
                ({ payload }) => {
                  setIsLoading(false);
                  if (typeof payload !== 'string') {
                    setCurrentStep(FacilitySteps.SUCCESS);
                  }
                },
              );
            }}
            goBack={() => {
              setCurrentStep(FacilitySteps.ADDRESS_AND_LANGUAGE_DETAILS);
            }}
            initialState={fields.admin}
            isLoading={isLoading}
          />
        );
      case FacilitySteps.SUCCESS:
        return <RegisterFacilityStepFive goHome={() => {}} />;
    }
  };

  return <div className="px-10">{getCurrentStep()}</div>;
};

interface RegisterFacilityProps {
  facility: FacilityI | null;
  _editFacility: (facility: FacilityI) => Promise<{
    type: string;
    payload: FacilityI | string;
  }>;
}

const mapStateToProps = ({ registrationState: { currentFacility } }: StoreI) => ({
  facility: currentFacility,
});

export default connect(mapStateToProps, { _editFacility: editFacility })(RegisterFacility);
