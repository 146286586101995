import { Button, ServiceRequestStatus } from '@mola/client-components';
import React from 'react';
import { connect } from 'react-redux';
import { ModalsState } from '../../../@types/redux/appRessourceStates/modals';
import { ReactComponent as CheckIcon } from '../../../assets/svg/check-icon.svg';
import { setCurrentModalType } from '../../../store/actions/creators/modals';
import {
  confirmCashPayment,
  queueRequest,
  rejectRequest,
} from '../../../store/actions/creators/requests';
import { RequestDetailsModalActions } from '../../../@types/requests';

const ConfirmationPopover: React.FC<ConfirmCashPopoverProps> = ({
  request,
  _confirmCashPayment,
  _setCurrentModalType,
  actionMessage,
  _rejectRequest,
  _queueRequest,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleClick = () => {
    switch (actionMessage) {
      case RequestDetailsModalActions.CONFIRM_CASH_PAYMENT:
        if (request) {
          _confirmCashPayment(request.id).then(({ payload }) => {
            setIsLoading(false);
            if (typeof payload !== 'string') {
              _setCurrentModalType({ type: null, minWidth: 'sm' });
            }
          });
        }
        return;

      case RequestDetailsModalActions.REJECT_ORDER:
        if (request) {
          _rejectRequest(request).then(({ payload }) => {
            setIsLoading(false);
            if (typeof payload !== 'string') {
              _setCurrentModalType({ type: null, minWidth: 'sm' });
            }
          });
        }
        return;

      case RequestDetailsModalActions.QUEUE:
        if (request) {
          _queueRequest(request).then(({ payload }) => {
            setIsLoading(false);
            if (typeof payload !== 'string') {
              _setCurrentModalType({ type: null, minWidth: 'sm' });
            }
          });
        }
        return;
    }
  };

  return (
    <div className="px-6 pt-3">
      <p className="text-grey mt-2">Do you really want to {actionMessage} ?</p>
      <Button
        className="button flex gap-2 mt-10"
        onClick={() => {
          setIsLoading(true);
          handleClick();
        }}
        isLoading={isLoading}
      >
        <CheckIcon className="mt-1" />
        Confirm
      </Button>
    </div>
  );
};

interface ConfirmCashPopoverProps {
  request: RequestI | null;
  _confirmCashPayment: (id: string) => Promise<{
    type: string;
    payload: { id: string; status: ServiceRequestStatus } | string;
  }>;
  _setCurrentModalType: (options: ModalsState['currentModal']) => {
    type: string;
    payload: ModalsState['currentModal'];
  };
  actionMessage: RequestDetailsModalActions;
  _rejectRequest: (request: RequestI) => Promise<{
    type: string;
    payload: RequestI | string;
  }>;

  _queueRequest: (request: RequestI) => Promise<{
    type: string;
    payload: RequestI | string;
  }>;
}

const mapStateToProps = ({ requestsState: { request } }: StoreI) => ({
  request,
});

export default connect(mapStateToProps, {
  _confirmCashPayment: confirmCashPayment,
  _setCurrentModalType: setCurrentModalType,
  _rejectRequest: rejectRequest,
  _queueRequest: queueRequest,
})(ConfirmationPopover);
