import React from 'react';
import { connect } from 'react-redux';
import { ReactComponent as SearchIcon } from '../../../assets/svg/search-icon.svg';
import { ReactComponent as CheckIcon } from '../../../assets/svg/check-icon.svg';
import { TextInput, SearchInput, Button } from '@mola/client-components';
import { Formik } from 'formik';
import { debounce } from 'lodash';
import { FormHelperText, Popover } from '@mui/material';
import PractitionerListPopover from './PractitionerListPopover';
import { assignPractitionerValidationSchema } from '../../../shared/schemas/requests';
import { getPractionners } from '../../../store/actions/creators/practitioners';
import { assignPractitioner } from '../../../store/actions/creators/requests';
import { PractitionersActionTypes } from '../../../store/actions/types/practitioners';
import { RequestsActionTypes } from '../../../store/actions/types/requests';
import { setCurrentModalType } from '../../../store/actions/creators/modals';
import { ModalsState } from '../../../@types/redux/appRessourceStates/modals';

const initialState = {
  practitionerName: '',
  facilityRoomNumber: '',
  startDate: '',
  startTime: '',
};

const AssignPractitionerPopover: React.FC<AssignPractitionerPopoverProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null);
  const [assignedPractitioner, setAssignedPractitioner] = React.useState<PractitionerI | null>(
    null,
  );

  const handleClick = debounce((event: any) => {
    setAnchorEl(event.target);
    setAssignedPractitioner(null);
    props._getPractitioners(event.target.value);
  }, 1000);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'search-practitionner-popup_id' : undefined;

  return (
    <Formik
      onSubmit={() => {
        // _login(values);
      }}
      validateOnChange
      initialValues={initialState}
      validationSchema={assignPractitionerValidationSchema}
    >
      {(formikProps) => {
        const { errors, isValid, handleChange, values } = formikProps;

        const isFormValid =
          isValid &&
          !errors.practitionerName &&
          !!assignedPractitioner &&
          !!values.practitionerName &&
          !errors.facilityRoomNumber &&
          !!values.facilityRoomNumber &&
          !errors.startDate &&
          !!values.startDate &&
          !errors.startTime &&
          !!values.startTime;

        return (
          <div className="p-6">
            <h3>Assign Practitioner</h3>

            <SearchInput
              labelKey=""
              forText="search"
              name="practitionerName"
              isRequired={false}
              onChange={(value, event) => {
                handleChange(event);
                if (value) {
                  handleClick(event);
                }
              }}
              value={values.practitionerName}
              placeHolder="Search Practitioner"
              onSearchClicked={() => {}}
              getSearchIcon={() => <SearchIcon />}
            />
            <FormHelperText error>{errors.practitionerName}</FormHelperText>

            <div>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <PractitionerListPopover
                  items={props.practitioners.rows}
                  loading={props.isLoadingPractitioners}
                  onSelected={(selectedPractitioner) => {
                    handleChange({
                      target: {
                        name: 'practitionerName',
                        value: `${selectedPractitioner.user.firstName} ${selectedPractitioner.user.lastName}`,
                      },
                    });
                    setAssignedPractitioner(selectedPractitioner);
                    handleClose();
                  }}
                />
              </Popover>
            </div>
            <TextInput
              labelKey=""
              forText="facilityRoomNumber"
              name="facilityRoomNumber"
              value={values.facilityRoomNumber}
              onChange={(value, event) => {
                handleChange(event);
              }}
              isRequired={false}
              classes={{}}
              placeHolder="Facility room number"
              errorMessage={errors.facilityRoomNumber}
            />
            <FormHelperText error>{errors.facilityRoomNumber}</FormHelperText>

            <div className="flex flex-row justify-between gap-5">
              <div>
                <TextInput
                  labelKey="Start Date"
                  forText="startDate"
                  name="startDate"
                  value={values.startDate}
                  isRequired={false}
                  onChange={(value, event) => {
                    handleChange(event);
                  }}
                  classes={{}}
                  placeHolder="Start date"
                  type="date"
                  errorMessage={errors.startDate}
                />
                <FormHelperText error>{errors.startDate}</FormHelperText>
              </div>
              <div>
                <TextInput
                  labelKey="Start Time"
                  forText="startTime"
                  name="startTime"
                  value={values.startTime}
                  isRequired={false}
                  onChange={(value, event) => {
                    handleChange(event);
                  }}
                  classes={{}}
                  placeHolder="Start time"
                  type="time"
                  errorMessage={errors.startTime}
                />
                <FormHelperText error>{errors.startTime}</FormHelperText>
              </div>
            </div>
            <Button
              type="button"
              className="button flex gap-2 mt-10 disabled:shadow-none"
              onClick={() => {
                if (assignedPractitioner) {
                  props
                    ._assignPractioner(props.request || null, {
                      practitioner: assignedPractitioner,
                      roomNumber: values.facilityRoomNumber,
                      startDate: new Date(`${values.startDate}T${values.startTime}`),
                    })
                    .then((res) => {
                      if (res.type === RequestsActionTypes.ASSIGN_PRACTITIONER) {
                        props.hideAssignPractitioner();
                        props._setCurrentModalType({ type: null, minWidth: 'md' });
                      }
                    });
                }
              }}
              isLoading={props.isLoadingAssignPractitioner}
              disabled={!isFormValid || props.isLoadingAssignPractitioner}
            >
              <CheckIcon className="mt-1" />
              Assign
            </Button>
          </div>
        );
      }}
    </Formik>
  );
};

interface AssignPractitionerPopoverProps {
  hideAssignPractitioner: () => void;
  request: Merge<{ practitioner?: PractitionerI }, RequestI> | null;
  practitioners: PaginatedItems<PractitionerI>;
  isLoadingPractitioners: boolean;
  isLoadingAssignPractitioner: boolean;
  _getPractitioners: (query: string) => Promise<{
    type: PractitionersActionTypes;
    payload: any;
  }>;
  _assignPractioner: (
    serviceRequest: Merge<{ practitioner?: PractitionerI }, RequestI> | null,
    practitionerData: {
      practitioner: PractitionerI;
      roomNumber: string;
      startDate: Date;
    },
  ) => Promise<{
    type: string;
    payload: any;
  }>;
  _setCurrentModalType: (options: ModalsState['currentModal']) => {
    type: string;
    payload: ModalsState['currentModal'];
  };
}

const mapStateToProps = ({
  requestsState: { request, isLoadingAssignPractitioner },
  practitionersState: { practitioners, isLoadingPractitioners },
}: StoreI) => ({
  request,
  isLoadingAssignPractitioner,
  practitioners,
  isLoadingPractitioners,
});

export default connect(mapStateToProps, {
  _getPractitioners: getPractionners,
  _assignPractioner: assignPractitioner,
  _setCurrentModalType: setCurrentModalType,
})(AssignPractitionerPopover);
