import { RegistrationActionTypes } from '../../store/actions/types/registration';
import { Dispatch } from 'redux';

export const setCurrentUserWithDispatch = (
  dispatch: Dispatch,
  user: UserI,
  isAuth: boolean,
  storage: 'localStorage' | 'sessionStorage',
) => {
  return dispatch({
    type: RegistrationActionTypes.SET_CURRENT_USER,
    payload: { user, isAuth, storage },
  });
};
