import { FormControlLabel, Select, Switch } from '@mui/material';
import React, { useState } from 'react';
import { TextInput } from '@mola/client-components';

const PharmacyDetails: React.FC = () => {
  const [isDeliveryIncluded, setisDeliveryIncluded] = useState(false);
  return (
    <>
      <FormControlLabel
        control={
          <Switch
            onChange={() => {
              setisDeliveryIncluded(!isDeliveryIncluded);
            }}
            value={isDeliveryIncluded}
          />
        }
        label="Is delivery included?"
        labelPlacement="start"
        className="text-dark-3 flex flex-row justify-between"
        sx={{ display: 'flex', marginLeft: '0' }}
      />

      {isDeliveryIncluded && (
        <div className="mt-4 w-full">
          <div className="mt-4 w-full">
            <label
              htmlFor="facility-category"
              className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
            >
              Delivery driver
            </label>
            <Select
              name="gender"
              id="user-gender"
              className="rounded-md h-10 mt-2 w-full px-1 active"
              native
            >
              <option value="male" key={0} className="hover:bg-grey-4 cursor-pointer">
                Select
              </option>
            </Select>
          </div>
          <TextInput
            labelKey="Delivery Adress"
            forText="location"
            name="location"
            isRequired
            classes={{}}
          />
          <TextInput
            labelKey="Delivery fees (Ksh)"
            forText="fees"
            name="fees"
            isRequired
            classes={{}}
          />
        </div>
      )}
    </>
  );
};

export default PharmacyDetails;
