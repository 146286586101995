import React, { useState } from 'react';
import { TextInput } from '@mola/client-components';
import { ReactComponent as Step1Icon } from '../../../assets/svg/4StepsSterpper-step4.svg';
import { ReactComponent as DollarIcon } from '../../../assets/svg/dolar-icon.svg';
import { Select } from '@mui/material';

export enum PaymentType {
  CARD = 'Card',
  PAYPAL = 'Paypal',
  MOBILE_MONEY = 'Mobile money',
  INSURANCE = 'Insurance',
  CASH = 'Cash',
}

export enum InsuranceType {
  UAP = 'UAP',
}

const Step1: React.FC<StepProps> = ({ goBack, goNext }) => {
  const [fields, setFields] = useState({
    paymentType: PaymentType.CARD,
  });

  return (
    <div className="p-6">
      <h3 className="flex flex-row justify-between gap-20 text-primary text-xl pb-2">
        <span className="">Payment details</span> <Step1Icon className="mt-2 " />
      </h3>

      <div className="flex flex-row justify-between gap-2 mt-10">
        <DollarIcon className="flex-wrap" />{' '}
        <div className="flex-grow">
          <div className="flex flex-row justify-between">
            <span>Mola service fees:</span> <span>100 Ksh</span>
          </div>
          <div className="flex flex-row justify-between font-bold">
            <span>Total to be payed:</span> <span>3,000 Ksh</span>
          </div>
        </div>
      </div>

      <div className="mt-4 w-full">
        <label
          htmlFor="facility-category"
          className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
        >
          Select payment Method
        </label>
        <Select
          name="gender"
          id="user-gender"
          className="rounded-md h-10 mt-2 w-full px-1"
          value={fields.paymentType}
          onChange={({ target: { value } }) => {
            setFields({
              ...fields,
              paymentType: value as PaymentType,
            });
          }}
          native
        >
          {Object.values(PaymentType).map((value) => (
            <option
              value={value}
              key={Math.random()}
              className="hover:bg-grey-4 cursor-pointer px-2 py-1"
            >
              {value}
            </option>
          ))}
        </Select>
      </div>
      {(fields.paymentType === PaymentType.CARD || fields.paymentType === PaymentType.PAYPAL) && (
        <>
          <TextInput
            labelKey="Card Number"
            forText="lastName"
            name="lastName"
            isRequired={false}
            classes={{}}
            placeHolder="Card Number"
          />

          <div className="flex flex-row justify-between gap-5">
            <TextInput
              labelKey="Expire date"
              forText="lastName"
              name="lastName"
              isRequired={false}
              classes={{}}
              placeHolder="Search Practitioner"
              type="date"
            />
            <TextInput
              labelKey="Cvv"
              forText="lastName"
              name="lastName"
              isRequired={false}
              classes={{}}
              placeHolder="Cvv"
            />
          </div>
        </>
      )}

      {fields.paymentType === PaymentType.INSURANCE && (
        <>
          <div className="mt-4 w-full">
            <label
              htmlFor="facility-category"
              className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
            >
              Select Insurance
            </label>
            <Select
              name="gender"
              id="user-gender"
              className="rounded-md h-10 mt-2 w-full px-1"
              native
            >
              {Object.values(InsuranceType).map((value) => (
                <option
                  value={value}
                  key={Math.random()}
                  className="hover:bg-grey-4 cursor-pointer px-2 py-1"
                >
                  {value}
                </option>
              ))}
            </Select>
          </div>

          <TextInput
            labelKey="Membership number"
            forText="lastName"
            name="lastName"
            isRequired={false}
            classes={{}}
            placeHolder="membership number"
          />
        </>
      )}

      {fields.paymentType === PaymentType.MOBILE_MONEY && (
        <TextInput
          labelKey="Telephone Number"
          forText="lastName"
          name="lastName"
          isRequired={false}
          classes={{}}
          placeHolder="Telephone Number"
        />
      )}

      <div className="flex flex-row justify-between">
        <button
          type="button"
          className="button flex gap-2 mt-10"
          onClick={() => {
            goBack();
          }}
        >
          Back
        </button>
        <button
          type="button"
          className="button flex gap-2 mt-10"
          onClick={() => {
            goNext();
          }}
        >
          Initiate payment
        </button>
      </div>
    </div>
  );
};

interface StepProps {
  goNext: () => void;
  goBack: () => void;
}
export default Step1;
